import React, {FC} from 'react';
import {motion} from 'framer-motion';
import {Feature} from '../../types';
import UINoImage from '../UICommon/UINoImage';
import FeatureButton from './FeatureButton';
import FeatureMedia from './FeatureMedia';
import FeatureMediaURL from './FeatureMediaURL';
import FeatureTitle from './FeatureTitle';
import FeatureButtonWrapper from './FeatureButtonWrapper';

type FeatureSplitRightProps = {
  details: Feature;
  color: string;
};

const FeatureSplitRight: FC<FeatureSplitRightProps> = ({details, color}) => {
  const title = details.title ? details.title : details.title;
  const subheader = details.subheader;

  const titleInfo = (elem: any) => {
    if (elem?.artikel?.data !== null && elem?.artikel) {
      const artikelTitle = {
        id: Math.random(),
        textFirstPart: elem?.artikel?.data?.attributes?.extraTitle !== null &&
        elem?.artikel?.data?.attributes?.extraTitle.length > 0 ?
        elem?.artikel?.data?.attributes?.extraTitle : '',
        variant: 'black',
      };
      return (
        <FeatureTitle title={artikelTitle} />
      );
    } else {
      return (
        <FeatureTitle
          title={title}
          objekt={elem.objekt}
          nyproduktion={elem.nyproduktion}
          artikel={elem.artikel}
        />
      );
    }
  };

  const mapSubheader = (elem: any) => {
    if (elem.objekt !== undefined && elem.objekt.data !== null && elem.objekt) {
      const objekt = elem?.objekt?.data?.attributes;
      return (objekt?.facts?.price ? objekt?.facts?.price.replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + ' / ' : '') +
      (objekt?.facts?.boarea ? 'Boarea ' + objekt?.facts?.boarea +' / ' : '') +
      (objekt?.numberOfRooms ? objekt?.numberOfRooms + ' / ' : '') +
      (objekt?.facts?.fee ? objekt?.facts?.fee + ' / ' : '') +
      (objekt?.facts?.plot ? 'Tomt ' + objekt?.facts?.plot : '');
    } else if (elem.nyproduktion !== undefined && elem.nyproduktion.data !== null && elem.nyproduktion) {
      return elem.nyproduktion?.data?.attributes?.kortFakta;
    } else if (elem.artikel !== undefined && elem.artikel.data !== null && elem.artikel) {
      return null;
    } else {
      return subheader?.replaceAll('null', '-');
    }
  };

  const mapDescription = (elem: any) => {
    if (details?.artikel?.data !== null && details?.artikel) {
      return details?.artikel?.data?.attributes?.Title;
    } else if (elem?.objekt !== undefined && elem?.objekt?.data !== null && elem?.objekt) {
      return elem.objekt?.data?.attributes?.salesTitle;
    } else if (elem?.nyproduktion !== undefined && elem?.nyproduktion?.data !== null && elem?.nyproduktion) {
      return elem?.nyproduktion?.data?.attributes?.salesTitle;
    } else if (elem?.artikel !== undefined && elem?.artikel?.data !== null && elem?.artikel) {
      return elem?.artikel?.data?.attributes?.Title;
    } else {
      return elem?.description;
    }
  };

  return (
    <div className='flex flex-col md:flex-row md:justify-between w-full mb-3 md:my-5 h-600 md:h-450 lg:h-530'>
      <div className='w-full md:w-49p h-96 md:h-auto overflow-hidden'>
        {details.media !== undefined && details.media.data !== null && (
          <FeatureMedia media={details?.media} />
        )}
        {details.media === undefined && (
          <FeatureMediaURL images={details?.images} />
        )}
        {details?.media?.data === null ? (
          details.objekt.data !== null ? (
            <FeatureMediaURL
              images={details?.objekt?.data?.attributes?.images}
            />
          ) : details.nyproduktion.data !== null ? (
            <FeatureMediaURL
              images={details?.nyproduktion?.data?.attributes?.images}
            />
          ) : details.artikel.data !== null ? (
            <FeatureMediaURL
              images={details?.artikel?.data?.attributes?.images}
            />
          ) : (
            <UINoImage />
          )
        ) : (
          <UINoImage />
        )}
      </div>
      <FeatureButtonWrapper
        buttons={details.buttons}
        objekt={details.objekt}
        nyproduktion={details.nyproduktion}
        artikel={details.artikel}
        style={
          `${color} w-full ` +
          'w-full md:w-49p flex flex-col justify-center lg:px-10 xl:px-24'
        }>
        <div className={`p-8 ${details.textVariant === 'center' ? 'text-center' : ''}`}>
          <div className={`flex ${details.textVariant === 'center' ? 'flex-col-reverse mb-4' : 'flex-col'}`}>
            {details && (
              <motion.div
                initial={{opacity: 0, x: 20}}
                whileInView={{opacity: [0, 0.3, 0.8, 1], x: 0}}
                viewport={{once: true}}
                transition={{
                  delay: 0.4,
                  x: {type: 'easeOut', stiffness: 100},
                  default: {duration: 1},
                }}
              >
                {titleInfo(details)}
              </motion.div>
            )}
            {details && (
              <motion.div
                initial={{opacity: 0, x: 20}}
                whileInView={{opacity: [0, 0.3, 0.8, 1], x: 0}}
                viewport={{once: true}}
                transition={{
                  delay: 0.6,
                  x: {type: 'easeOut', stiffness: 100},
                  default: {duration: 1},
                }}className='text-xs md:text-sm lg:text-15 pt-2 md:pt-6 pb-3 font-bold'>
                {mapSubheader(details)}
              </motion.div>
            )}
          </div>
          {(details.description ||
            details?.artikel?.data?.attributes?.Title ||
            details?.nyproduktion?.data?.attributes?.salesTitle ||
            details?.objekt?.data?.attributes?.salesTitle) && (
            <motion.div
              initial={{opacity: 0, x: 20}}
              whileInView={{opacity: [0, 0.3, 0.8, 1], x: 0}}
              viewport={{once: true}}
              transition={{
                delay: 0.8,
                x: {type: 'easeOut', stiffness: 100},
                default: {duration: 1},
              }}
              className={`text-9 md:text-sm lg:text-15 pb-6 md:pb-10 ` +
              `${details.textVariant === 'center' ? 'pr-0 md:pr-0' : 'pr-10 md:pr-20' }`}>
              {mapDescription(details)}
            </motion.div>
          )}
          <motion.div
            initial={{opacity: 0, x: 20}}
            whileInView={{opacity: [0, 0.3, 0.8, 1], x: 0}}
            viewport={{once: true}}
            transition={{
              delay: 1,
              x: {type: 'easeOut', stiffness: 100},
              default: {duration: 1},
            }}
            className={`flex pt-6 ${details.textVariant === 'center' && 'justify-center'}`}
          >
            <FeatureButton
              buttons={details.buttons}
              objekt={details.objekt}
              nyproduktion={details.nyproduktion}
              artikel={details.artikel}
            />
          </motion.div>
        </div>
      </FeatureButtonWrapper>
    </div>
  );
};

export default FeatureSplitRight;
