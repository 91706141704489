import React, {FC} from 'react';
import {Link} from 'react-router-dom';
import {MdAdd} from 'react-icons/md';

type NewNestPageSearchResultProps = {
  title: string;
  secondText: string;
  description: string;
  subHeader: string;
  link: string;
  id?: number;
}

const NewNestPageSearchResult: FC<NewNestPageSearchResultProps> = ({
  title, secondText, description, subHeader, link, id}) => {
  return (
    <>
      {id ? (
          <Link to={'/' + link + '/' + id}>
            <div className='flex justify-between border-b border-black py-5'>
              <div className='w-1/6'>
                <p className='font-bold text-11'>{title}</p>
              </div>
              <div className='w-4/6 text-15'>
                <p>{secondText}</p>
                <p>{description}</p>
                <p>{subHeader}</p>
              </div>
              <div>
              </div>
              <MdAdd className="h-6 w-6" />
            </div>
          </Link>
        ) : (
          <Link to="/kontakt" replace>
            <div className='flex justify-between border-b border-black py-5'>
              <div className='w-1/6'>
                <p className='font-bold text-11'>{title}</p>
              </div>
              <div className='w-4/6 text-15'>
                <p>{secondText}</p>
                <p>{description}</p>
                <p>{subHeader}</p>
              </div>
              <div>
              </div>
              <MdAdd className="h-6 w-6" />
            </div>
          </Link>
        )}
    </>
  );
};

export default NewNestPageSearchResult;
