import React, {FC} from 'react';
import {GoPlus} from 'react-icons/go';
import {motion} from 'framer-motion';
import {Button, Buttons, Footer} from '../../types';
import addDefaultSrc from '../../utils/addDefultImageSrc';
import footerimg from '../../assets/footer-logo.png';

type AppFooterProps = {
  footerDetails: Footer;
  footerLinksDetails: Buttons;
}

const AppFooter: FC<AppFooterProps> = ({footerDetails, footerLinksDetails}) => {
  return (
    <div className='w-11/12 md:w-4/5 mx-auto h-full'>
      <div className='text-19 md:text-2xl lg:text-35 h-1/2 md:h-2/3 max-w-xl flex items-center'>
        {footerDetails.footerText && (
          <motion.div
            initial={{opacity: 0, y: 20}}
            whileInView={{opacity: [0, 0.3, 0.8, 1], y: 0}}
            viewport={{once: true}}
            transition={{
              delay: 0.4,
              x: {type: 'easeOut', stiffness: 100},
              default: {duration: 1},
            }} className='inline bold md:leading-9 lg:leading-12'>
            {footerDetails.footerText + ' '}
            <a
              href={`/${footerDetails?.footerButton?.buttonLink}`}
              className="border-b lg:border-b-2 pb-1 border-white inline"
            >
              {footerDetails?.footerButton?.buttonText}
              <GoPlus className="h-4 w-4 ml-4 mb-0.5 inline"/>
            </a>
          </motion.div>
        )}
      </div>
      <div className='h-1/2 md:h-1/3 flex flex-col md:flex-row justify-between items-start'>
        <motion.div
          initial={{opacity: 0, y: 20}}
          whileInView={{opacity: [0, 0.3, 0.8, 1], y: 0}}
          viewport={{once: true}}
          transition={{
            delay: 0.8,
            x: {type: 'easeOut', stiffness: 100},
            default: {duration: 1},
          }} className='flex items-end'>
          <div className='rounded-full w-32 h-32'>
            <img className='rounded-full border-2 border-white' src={footerimg} onError={addDefaultSrc}/>
          </div>
          <div className='text-6 md:text-11 m-4'>© 2022 New Nest. All Rights Reserved.</div>
        </motion.div>
        <div className='flex text-11 mt-14 pb-6 md:pb-0 px-4 md:px-0'>
          {footerLinksDetails && footerLinksDetails.map((el : Button, idx) => (
            <a
              key={idx}
              href={`https://${el.buttonLink}`}
              target="_blank"
              rel="noreferrer"
              className='mr-10 font-bold border-b border-white'
            >
              {el.buttonText}</a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AppFooter;
