import React, {FC} from 'react';
import {motion} from 'framer-motion';
import {AccordionMenu, MenuElement} from '../../types';
import UISingleMedia from '../UICommon/UISingleMedia';
import HomePageAccordionElement from './HomePageAccordionElement';

type HomePageAccordionMenuProps = {
  data: AccordionMenu;
}

const HomePageAccordionMenu: FC<HomePageAccordionMenuProps> = ({data}) => {
  return (
    <div className={
      'max-w-screen-2xl mx-auto min-h-530 relative flex justify-center items-center my-5 py-10'}>
      <div className='absolute w-full h-full -z-10 md:px-5 lg:px-10'>
        {data.image && <UISingleMedia media={data.image}/>}
      </div>
      <div className='px-8 md:px-16 lg:px-32 xl:px-52 max-w-screen-2xl mx-auto text-white'>
        {data.title && (
          <motion.div
            initial={{opacity: 0, x: -20}}
            whileInView={{opacity: [0, 0.3, 0.8, 1], x: 0}}
            viewport={{once: true}}
            transition={{
              delay: 0.4,
              x: {type: 'easeOut', stiffness: 100},
              default: {duration: 1},
            }} className='text-9 md:text-11 font-bold'>
            {data.title}
          </motion.div>)}
        {data?.menuElement && data?.menuElement.map((el: MenuElement, idx: number) => (
          <HomePageAccordionElement data={el} key={idx}/>
        ))}
      </div>
    </div>
  );
};

export default HomePageAccordionMenu;
