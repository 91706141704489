/* eslint-disable max-len */
import React from 'react';

const CookiesPage = () => {
  return (
    <div className="w-11/12 md:w-4/5 mx-auto h-full mt-20 text-9 md:text-sm lg:text-15">
      <h1 className="text-xl md:text-3xl lg:text-35 font-bold lg:leading-10 md:leading-8 leading-6 text-black mb-8">
      Integritets- och Cookiepolicy
      </h1>
      {/* <h2 className="text-md md:text-xl font-bold lg:leading-10 md:leading-8 leading-6 text-black mb-4">
        Integritets- och Cookiepolicy
      </h2> */}
      <p className="mb-4">New Nest, org.nr 556912-6682 med postadress Götgatan 78, 118 30 Stockholm ansvarar för de personuppgifter som inhämtas och behandlas när du besöker vår webbplats eller använder våra tjänster.</p>
      <h3 className="font-bold">New Nests personuppgiftshantering</h3>
      <p className="mb-4">Vi värnar om din personliga integritet och vi vill att du känner dig trygg när du lämnar personuppgifter till oss. Nedan beskriver vi närmare hur New Nest behandlar personuppgifter.</p>
      <h3 className="font-bold">Vilken information samlar vi in?</h3>
      <p className="mb-4">Det finns flera tillfällen då du kan komma att lämna över information om dig själv till oss. Om du anlitar någon fastighetsmäklare, gör en intresseanmälan, hör av dig till New Nest på något sätt eller besöker vår hemsida. Det förekommer tillfällen där vi kan komma att samla in information från tredje part, exempelvis ägarinformation från Lantmäteriet eller komplettering av adressuppgifter. Den information vi väljer att samla in om dig beror på hur vår kontakt uppstått. Personuppgifter som vi till exempel samlar in är:</p>
      <ul className="mb-4 list-disc pl-4">
        <li>Namn, personnummer, e-postadress, mobiltelefonnummer och adress</li>
        <li>Konto- och betalningsuppgifter för köpare och säljare</li>
        <li>Enhetsinformation som IP-adress och webbläsarinställningar</li>
      </ul>
      <h3 className="font-bold">Av vilken anledning behöver vi dina uppgifter?</h3>
      <p className="mb-4">Anledningen till den personuppgiftsbehandling som New Nest utför är för att kunna utföra, tillhandahålla och förbättra våra tjänster gentemot dig. Det finns flera orsaker till att vi kan behöva samla in, hantera och spara uppgifter om dig. Några rent juridiska, för att kunna följa gällande lagstiftning, exempelvis fastighetsmäklarlagen. Vid andra situationer behöver vi spara dina personuppgifter för att fullgöra våra avtal.
New Nest behandlar personuppgifter för följande ändamål, baserat på följande lagliga grunder:</p>
      <h3 className="font-bold">Syfte med personuppgiftsbehandlingen</h3>
      <p className="mb-4">För att bekräfta din identitet och verifiera dina person- och kontaktuppgifter <br/>
        För att kunna uppfylla avtal och åtaganden vi har gentemot dig <br/>
        För att kunna förmedla bostäder och tjänster till dig <br/>
        För att kunna ge dig den bästa möjliga kundupplevelsen och hjälp du kan behöva rörande våra tjänster <br/>
        För att kunna hantera ditt eventuella deltagande i tävlingar, event och göra utskick om erbjudanden samt marknadsföring. <br/>
        För kunna göra analyser i syfte att förbättra våra tjänster gentemot dig <br/>
        För att följa tillämplig lagstiftning, såsom bokföringslagar, lag om åtgärder mot penningtvätt och finansiering av terrorism samt fastighetsmäklarlagen</p>
      <h3 className="font-bold">Den lagliga grunden berättigat intresse</h3>
      <p className="mb-4">New Nest nyttjar den lagliga grunden berättigat intresse som innebär att behandlingen är nödvändig för att tillgodose vårt berättigade intresse, exempelvis genom att erbjuda dig boenderelaterade produkter och tjänster. Ett annat exempel är att vi följer upp ditt besök på en visning eller ditt köp av en bostad. Ett berättigat intresse är även att har möjlighet att uppdatera våra tjänster för att göra dem så användarvänliga som möjligt för dig. Vi behöver därför analysera hur våra tjänsterna används för att göra förbättringar gentemot dig som kund. New Nest vill ge dig rätt service i form av väsentlig kommunikation, olika tjänster samt anpassade erbjudanden. När berättigat intresse beskrivs i den lagliga grunden görs alltid en avvägning mellan intresset av New Nest:s behandling av personuppgifter och individens intresse av att skydda sina personuppgifter.</p>
      <h3 className="font-bold">Av vilken anledning behöver vi ditt personnummer?</h3>
      <p className="mb-4">Oftast behöver vi ditt personnummer för att det är enda sättet att kunna säkerställa din identitet vid till exempel avtalsskrivning. Vi behandlar ditt personnummer bara när det är nödvändigt med hänsyn till ändamålet.</p>
      <h3 className="font-bold">Hur lång tid behåller vi dina personuppgifter?</h3>
      <p className="mb-4">Personuppgifter lagras av New Nest under den tid det är nödvändigt för de ändamål som specificeras ovan. Det kan vara så länge som det är nödvändigt för att kunna utföra det vi lovat dig enligt våra avtal eller andra åtaganden. Det kan även vara så länge det krävs enligt lag eller myndighetsbeslut. New Nest försöker att hålla de personuppgifter som behandlas aktuella och raderar inaktuella och överflödiga personuppgifter.</p>
      <h3 className="font-bold">Tredje parter</h3>
      <p className="mb-4">Inom ramen för våra förmedlingsuppdrag och tjänster samarbetar vi med tjänsteleverantörer och underleverantörer som kan komma att ta del av personuppgifter. Vi delar endast sådana personuppgifter som är nödvändiga för att fullgöra åtagandet och vi kräver alltid att våra samarbetspartners skyddar personuppgifterna i enlighet med vår policy. Till exempel har en fastighetsmäklare enligt lag en skyldighet att tillhanda Fastighetsmäklarinspektionen (FMI) uppgifter och handlingar som FMI begär i sin tillsyn.
New Nest säljer aldrig personuppgifter och delar inte personuppgifter med obehöriga.
Vår webbplats innehåller länkar till våra samarbetspartners. Om du klickar på en länk till någon av våra samarbetspartners ansvarar vår samarbetspartner för behandling av personuppgifter du lämnar till dem.</p>
      <h3 className="font-bold">Dina rättigheter</h3>
      <p className="mb-4">Som kund hos oss har du följande rättigheter i förhållande till de personuppgifter vi behandlar om dig:<br/>
  Rätt till information<br/>
 Du har alltid rätt att begära oss ett utdrag på vilka personuppgifter vi behandlar om dig<br/>
   Rätt till rättelse<br/>
 Du har alltid rätt att begära oss att rätta personuppgifter vi behandlar om dig om du anser dem vara felaktiga<br/>
   Rätt till radering<br/>
 Du har under vissa omständigheter rätt att begära oss att radera personuppgifter.<br/>
   Rätt till begränsning<br/>
 Du har under vissa omständigheter rätt att begära oss begränsa behandlingen av dina personuppgifter<br/>
   Rätt till invändning<br/>
 Du har under vissa omständigheter möjlighet att motsätta dig vår behandling av dina personuppgifter.<br/>
   Rätt till dataportabilitet<br/>
 Du har under vissa förutsättningar rätt att begära oss att överföra dina personuppgifter vi behandlar till ett annat bolag eller till dig.<br/>
Om du önskar utnyttja dina rättigheter enligt ovan, vänligen kontakta oss på <a href="mailto:hello@newnest.se">hello@newnest.se</a></p>
      <h3 className="font-bold">Ändringar i integritetspolicyn</h3>
      <p className="mb-4">Vi publicerar eventuella uppdateringar av integritetspolicyn på denna webbplats. Vid större uppdateringar i vårt dataskyddsarbete meddelar vi dig via e-post innan ändringen träder i kraft. Denna integritetspolicy är senast uppdaterad den 4 juli 2022.</p>
      <h3 className="font-bold">Kontakt</h3>
      <p className="mb-4">Om du har frågor kring vår integritetspolicy, vilka personuppgifter vi behandlar eller om du vill utnyttja dina rättigheter, vänligen kontakta oss på <a href="mailto:hello@newnest.se">hello@newnest.se</a></p>
      <h3 className="font-bold">Klagomål</h3>
      <p className="mb-4">Du har alltid rätt att kontakta Datainspektionen om du anser att dina personuppgifter har blivit behandlade på ett felaktigt sätt.</p>
      <h3 className="font-bold">Cookies</h3>
      <p className="mb-4">Cookies (eller kakor) är små textfiler som lagras på din enhet när du besöker en webbplats. Kakor tillåter webbsidor att identifiera din enhet och ger webbtjänsten information om hur du använder sidan. Vi använder både permanenta kakor som lagras på din enhet under en bestämd tid och sessionskakor som försvinner när du stänger din webbläsare.
New Nest samarbetar med tredje parter för analys av webbplatsen och för riktad annonsering. Vi använder oss av tredjepartskakor från Google Analytics för att:</p>
      <ul className="mb-4 list-disc pl-4">
        <li>mäta och analysera hur kunder rör sig på vår webbplats för att göra webbplatsen mer användarvänlig</li>
        <li>få tillgång till tillförlitlig och anonym besökshistorik</li>
        <li>visa riktade annonser på andra webbplatser som bygger på dina surfningsaktiviteter på andra webbplatser</li>
      </ul>
      <p className="mb-4">Om du vill radera kakor som lagrats på din dator, finns det information i din webbläsares inställningar om hur du raderar kakor. Ytterligare information om cookies hittar du på <a href="https://cookiesandyou.com/" target="_blank" rel="noreferrer">https://cookiesandyou.com/</a>. Genom att radera eller inaktivera framtida cookies kan din användarupplevelse påverkas.</p>
    </div>
  );
};

export default CookiesPage;
