import React from 'react';
import AppLayout from './components/AppLayout/AppLayout';
import ScrollToTop from './utils/scrollToTop';
import {BrowserRouter as Router} from 'react-router-dom';

function App() {
  return (
    <div>
      <Router>
        <ScrollToTop />
        <AppLayout />
      </Router>
    </div>
  );
}

export default App;
