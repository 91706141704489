import React, {FC} from 'react';
import {motion} from 'framer-motion';

type AccordionBudgivningPartProps = {
  setIsSecondActive: any;
  isSecondActive: any;
  bidValue: string;
  bidText: string;
  realtors: any;
}

const AccordionBudgivningPart: FC<AccordionBudgivningPartProps> = ({
  setIsSecondActive,
  isSecondActive,
  bidValue,
  bidText,
  realtors,
}) => {
  return (
    <div className="accordion-item">
      <div className="flex items-center cursor-pointer my-1 md:my-2" onClick={() => setIsSecondActive(!isSecondActive)}>
        <div className='text-xl md:text-3xl mr-4'>{isSecondActive ? '-' : '+'}</div>
        <div className='text-xl md:text-3xl'>Budgivning pågår</div>
      </div>
      <div className={
        `${isSecondActive ? 'h-auto mb-4 md:mb-10 transition-all duration-300 ' :
                  'h-0 transition-all overflow-hidden opacity-0 duration-300 '}` +
                  'md:flex justify-between w-full'
      }>
        <div className='text-sm font-normal pl-7 w-full'>
          {bidValue && (
            <motion.div
              initial={{opacity: 0, y: 20}}
              whileInView={{opacity: [0, 0.3, 0.8, 1], y: 0}}
              viewport={{once: true}}
              transition={{
                delay: 0.2,
                x: {type: 'easeOut', stiffness: 100},
                default: {duration: 1},
              }}
              className='font-bold py-4'>Senaste bud {bidValue.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} kr</motion.div>)}
          {realtors && (
            <motion.div
              initial={{opacity: 0, y: 20}}
              whileInView={{opacity: [0, 0.3, 0.8, 1], y: 0}}
              viewport={{once: true}}
              transition={{
                delay: 0.6,
                x: {type: 'easeOut', stiffness: 100},
                default: {duration: 1},
              }} className='pb-4'>
                          Kontakta Ansvarig mäklare för mer information <br/>
              {realtors[0].name} <a href={`tel:${realtors[0].phone}`}>{realtors[0].phone} </a>
              <a href={`mailto:${realtors[0].email}`}>{realtors[0].email}</a>
            </motion.div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AccordionBudgivningPart;
