import React, {FC} from 'react';
import {Title} from '../../types';

type FeatureTitleProps = {
  title: Title;
  objekt?: any;
  nyproduktion?: any;
  artikel?: any;
}

const FeatureTitle: FC<FeatureTitleProps> = ({title, objekt, nyproduktion, artikel}) => {
  if (objekt !== undefined && objekt.data !== null && objekt) {
    const newNest = objekt?.data?.attributes?.isNewConstruction ? 'new ' : 'nest ';
    return (
      <>
        {title && title?.variant && title.variant === 'beige-black' && (
          <div className='text-xl md:text-3xl lg:text-30 font-bold lg:leading-10 md:leading-8 leading-6'>
            {objekt?.data?.attributes?.municipality && <div className='text-beige-accent'>
              {newNest}
              {objekt?.data?.attributes?.municipality}
            </div>}
            {objekt?.data?.attributes?.address && <div className='text-black'>
              {objekt?.data?.attributes?.address}
            </div>}
          </div>
        )}
        {title && title.variant && title.variant === 'black-beige' && (
          <div className='text-xl md:text-3xl lg:text-30 font-bold lg:leading-10 md:leading-8 leading-6'>
            {objekt?.data?.attributes?.municipality && <div className='text-black'>
              {newNest}
              {objekt?.data?.attributes?.municipality}
            </div>}
            {objekt?.data?.attributes?.address && <span className='text-beige-accent'>
              {objekt?.data?.attributes?.address}
            </span>}
          </div>
        )}
        {title && title.variant && title.variant === 'black' && (
          <div className='text-xl md:text-3xl lg:text-30 font-bold lg:leading-10 md:leading-8 leading-6'>
            {objekt?.data?.attributes?.municipality && <div className='text-black'>
              {newNest}
              {objekt?.data?.attributes?.municipality}
            </div>}
            {objekt?.data?.attributes?.address && <div className='text-black'>
              {objekt?.data?.attributes?.address}
            </div>}
          </div>
        )}
        {title === null || title?.variant === null && (
          <div className='text-xl md:text-3xl lg:text-30 font-bold lg:leading-10 md:leading-8 leading-6'>
            {objekt?.data?.attributes?.municipality && <div className='text-beige-accent'>
              {newNest}
              {objekt?.data?.attributes?.municipality}
            </div>}
            {objekt?.data?.attributes?.address && <div className='text-black'>
              {objekt?.data?.attributes?.address}
            </div>}
          </div>
        )}
        {title === null && (
          <div className='text-xl md:text-3xl lg:text-30 font-bold lg:leading-10 md:leading-8 leading-6'>
            {objekt?.data?.attributes?.municipality && <div className='text-beige-accent'>
              {newNest}
              {objekt?.data?.attributes?.municipality}
            </div>}
            {objekt?.data?.attributes?.address && <div className='text-black'>
              {objekt?.data?.attributes?.address}
            </div>}
          </div>
        )}
      </>
    );
  } else if (nyproduktion !== undefined && nyproduktion.data !== null && nyproduktion) {
    return (
      <>
        {title && title.variant && title.variant === 'beige-black' && (
          <div className='text-xl md:text-3xl lg:text-30 font-bold lg:leading-10 md:leading-8 leading-6'>
            {nyproduktion?.data?.attributes?.municipality && <div className='text-beige-accent'>
              new {nyproduktion?.data?.attributes?.municipality}
            </div>}
            {nyproduktion?.data?.attributes?.projectName && <div className='text-black'>
              {nyproduktion?.data?.attributes?.projectName}
            </div>}
          </div>
        )}
        {title && title.variant && title.variant === 'black-beige' && (
          <div className='text-xl md:text-3xl lg:text-30 font-bold lg:leading-10 md:leading-8 leading-6'>
            {nyproduktion?.data?.attributes?.municipality && <span className='text-black'>
              new {nyproduktion?.data?.attributes?.municipality}
            </span>}
            {nyproduktion?.data?.attributes?.projectName && <span className='text-beige-accent'>
              {nyproduktion?.data?.attributes?.projectName}
            </span>}
          </div>
        )}
        {title && title.variant && title.variant === 'black' && (
          <div className='text-xl md:text-3xl lg:text-30 font-bold lg:leading-10 md:leading-8 leading-6'>
            {nyproduktion?.data?.attributes?.municipality && <div className='text-black'>
              new {nyproduktion?.data?.attributes?.municipality}
            </div>}
            {nyproduktion?.data?.attributes?.projectName && <div className='text-black'>
              {nyproduktion?.data?.attributes?.projectName}
            </div>}
          </div>
        )}
        {title && title?.variant === null && (
          <div className='text-xl md:text-3xl lg:text-30 font-bold lg:leading-10 md:leading-8 leading-6'>
            {nyproduktion?.data?.attributes?.municipality && <div className='text-beige-accent'>
              new {nyproduktion?.data?.attributes?.municipality}
            </div>}
            {nyproduktion?.data?.attributes?.projectName && <div className='text-black'>
              {nyproduktion?.data?.attributes?.projectName}
            </div>}
          </div>
        )}
        {title === null && (
          <div className='text-xl md:text-3xl lg:text-30 font-bold lg:leading-10 md:leading-8 leading-6'>
            {nyproduktion?.data?.attributes?.municipality && <div className='text-beige-accent'>
              new {nyproduktion?.data?.attributes?.municipality}
            </div>}
            {nyproduktion?.data?.attributes?.projectName && <div className='text-black'>
              {nyproduktion?.data?.attributes?.projectName}
            </div>}
          </div>
        )}
      </>
    );
  } else if (artikel !== undefined && artikel.data !== null && artikel) {
    return (
      <>
        {title && title.variant && title.variant === 'beige-black' && (
          <div className='text-xl md:text-3xl lg:text-30 font-bold lg:leading-10 md:leading-8 leading-6'>
            {artikel?.data?.attributes?.municipality && <div className='text-beige-accent'>
              {artikel?.data?.attributes?.municipality}
            </div>}
            {artikel?.data?.attributes?.Title && <div className='text-black'>{artikel?.data?.attributes?.Title}</div>}
          </div>
        )}
        {title && title.variant && title.variant === 'black-beige' && (
          <div className='text-xl md:text-3xl lg:text-30 font-bold lg:leading-10 md:leading-8 leading-6'>
            {artikel?.data?.attributes?.municipality && <div className='text-black'>
              {artikel?.data?.attributes?.municipality}
            </div>}
            {artikel?.data?.attributes?.Title && <div className='text-black'>{artikel?.data?.attributes?.Title}</div>}
          </div>
        )}
        {title && title.variant && title.variant === 'black' && (
          <div className='text-xl md:text-3xl lg:text-30 font-bold lg:leading-10 md:leading-8 leading-6'>
            {artikel?.data?.attributes?.municipality && <div className='text-black'>
              {artikel?.data?.attributes?.municipality}
            </div>}
            {artikel?.data?.attributes?.Title && <div className='text-black'>{artikel?.data?.attributes?.Title}</div>}
          </div>
        )}
        {title === null || title?.variant === null && (
          <div className='text-xl md:text-3xl lg:text-30 font-bold lg:leading-10 md:leading-8 leading-6'>
            {artikel?.data?.attributes?.municipality && <div className='text-black'>
              {artikel?.data?.attributes?.municipality}
            </div>}
            {artikel?.data?.attributes?.Title && <div className='text-black'>{artikel?.data?.attributes?.Title}</div>}
          </div>
        )}
        {title === null && (
          <div className='text-xl md:text-3xl lg:text-30 font-bold lg:leading-10 md:leading-8 leading-6'>
            {artikel?.data?.attributes?.municipality && <div className='text-black'>
              {artikel?.data?.attributes?.municipality}
            </div>}
            {artikel?.data?.attributes?.Title && <div className='text-black'>{artikel?.data?.attributes?.Title}</div>}
          </div>
        )}
      </>
    );
  } else {
    return (
      <>
        {title && title.variant && title.variant === 'beige-black' && (
          <div className='text-xl md:text-3xl lg:text-30 font-bold lg:leading-10 md:leading-8 leading-6'>
            {title.textFirstPart && (
              <div className='text-beige-accent' dangerouslySetInnerHTML={{__html: title.textFirstPart}}></div>)}
            {title.textSecondPart && (
              <div className='text-black' dangerouslySetInnerHTML={{__html: title.textSecondPart}}></div>)}
          </div>
        )}
        {title && title.variant && title.variant === 'black-beige' && (
          <div className='text-xl md:text-3xl lg:text-30 font-bold lg:leading-10 md:leading-8 leading-6'>
            {title.textFirstPart && (
              <span className='text-black' dangerouslySetInnerHTML={{__html: title.textFirstPart}}></span>)}
            {title.textSecondPart && (
              <span className='text-beige-accent' dangerouslySetInnerHTML={{__html: title.textSecondPart}}></span>)}
          </div>
        )}
        {title && title.variant && title.variant === 'black' && (
          <div className='text-xl md:text-3xl lg:text-30 font-bold lg:leading-10 md:leading-8 leading-6'>
            {title.textFirstPart && (
              <div className='text-black' dangerouslySetInnerHTML={{__html: title.textFirstPart}}></div>)}
            {title.textSecondPart && (
              <div className='text-black' dangerouslySetInnerHTML={{__html: title.textSecondPart}}></div>)}
          </div>
        )}
        {title === null || title?.variant === null && (
          <div className='text-xl md:text-3xl lg:text-30 font-bold lg:leading-10 md:leading-8 leading-6'>
            {title.textFirstPart && (
              <div className='text-black' dangerouslySetInnerHTML={{__html: title.textFirstPart}}></div>)}
            {title.textSecondPart && (
              <div className='text-black' dangerouslySetInnerHTML={{__html: title.textSecondPart}}></div>)}
          </div>
        )}
      </>
    );
  }
};

export default FeatureTitle;
