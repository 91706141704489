import React, {FC, useState} from 'react';
import {motion} from 'framer-motion';
import {MenuElement} from '../../types';
import UIButton from '../UICommon/UIButton';

type HomePageAccordionElementProps = {
  data: MenuElement;
}

const HomePageAccordionElement: FC<HomePageAccordionElementProps> = ({data}) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <div className="accordion-item">
      <motion.div
        initial={{opacity: 0, x: -20}}
        whileInView={{opacity: [0, 0.3, 0.8, 1], x: 0}}
        viewport={{once: true}}
        transition={{
          delay: 0.4,
          x: {type: 'easeOut', stiffness: 100},
          default: {duration: 1},
        }}
        className="flex items-center cursor-pointer my-1 md:my-2 lg:my-5 justify-between"
        onClick={() => setIsActive(!isActive)}>
        {data.title && <div className='text-xl md:text-3xl lg:text-40 text-white'>{data.title}</div>}
        <div className='text-xl md:text-3xl lg:text-40 ml-10'>{isActive ? '-' : '+'}</div>
      </motion.div>
      <div className={
        `${isActive === true ? 'h-auto mb-4 md:mb-10 transition-all duration-300 ' :
        'h-0 transition-all overflow-hidden opacity-0 duration-300 '}` +
        'md:flex justify-between'
      }>
        {data.description && <div className='text-xs md:text-base md:w-2/3 text-white'>{data.description}</div>}
        <div className='md:w-1/3 flex items-end justify-end'>
          {data.button && (<UIButton button={data.button}/>)}
        </div>
      </div>
      <motion.div
        initial={{width: 0}}
        whileInView={{width: '100%'}}
        viewport={{once: true}}
        transition={{
          delay: 0.6,
          x: {type: 'easeOut', stiffness: 100},
          default: {duration: 2},
        }}
        className='bg-white h-1px divide-white'></motion.div>
    </div>
  );
};

export default HomePageAccordionElement;
