import React, {FC} from 'react';
import {motion} from 'framer-motion';

type AccordionVisningPartProps = {
  setIsThirdActive: any;
  isThirdActive: any;
  showings: any;
}

const AccordionVisningPart: FC<AccordionVisningPartProps> = ({
  setIsThirdActive,
  isThirdActive,
  showings,
}) => {
  return (
    <div className="accordion-item">
      <div className="flex items-center cursor-pointer my-1 md:my-2" onClick={() => setIsThirdActive(!isThirdActive)}>
        <div className='text-xl md:text-3xl mr-4'>{isThirdActive ? '-' : '+'}</div>
        <div className='text-xl md:text-3xl'>Visning</div>
      </div>
      <div className={
        `${isThirdActive ? 'h-auto mb-4 md:mb-10 transition-all duration-300 ' :
          'h-0 transition-all overflow-hidden opacity-0 duration-300 '}` +
        'md:flex justify-between w-full'
      }>
        <div className='text-sm font-normal pl-7 w-full'>
          {showings.map((el: any, idx: any) => (
            <>
              <motion.div
                initial={{opacity: 0, y: 20}}
                whileInView={{opacity: [0, 0.3, 0.8, 1], y: 0}}
                viewport={{once: true}}
                transition={{
                  delay: 0.2,
                  x: {type: 'easeOut', stiffness: 100},
                  default: {duration: 1},
                }} className='py-4'>
                {el.comment}
              </motion.div>
              <motion.div
                initial={{opacity: 0, y: 20}}
                whileInView={{opacity: [0, 0.3, 0.8, 1], y: 0}}
                viewport={{once: true}}
                transition={{
                  delay: 0.1 * idx,
                  x: {type: 'easeOut', stiffness: 100},
                  default: {duration: 1},
                }} key={idx} className="font-bold">
                {el.startDate.slice(0, -3)} - {el.endDate.slice(0, -3).substring(10)}
              </motion.div>
            </>
          ))}
          <motion.div
            className='my-4'
            initial={{opacity: 0, y: 20}}
            whileInView={{opacity: [0, 0.3, 0.8, 1], y: 0}}
            viewport={{once: true}}
            transition={{
              delay: 0.8,
              x: {type: 'easeOut', stiffness: 100},
              default: {duration: 1},
            }}>
            <a className='underline font-bold py-4' href="#contact">Jag vill anmäla mig</a>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default AccordionVisningPart;
