import React, {FC} from 'react';
import LazyLoad from 'react-lazyload';
import UIImageChooseLink from '../UICommon/UIImageChooseLink';
import UINoImage from '../UICommon/UINoImage';

type ContactPageMediaProps = {
  item: any;
}

const ContactPageMedia: FC<ContactPageMediaProps> = ({item}) => {
  const showMedia = (item: any) => {
    if (item?.vimeo !== null && item?.vimeo?.length > 0) {
      let segments = [];
      let videoId = '';
      segments = item?.vimeo?.split('/');
      videoId = segments[segments.length - 1];
      return (
        <div className="w-full h-full overflow-x-hidden relative">
          <iframe
            title={'title'}
            // eslint-disable-next-line max-len
            src={`https://player.vimeo.com/video/${videoId}?speed=0&autoplay=1&controls=0&background=1&quality=720p&loop=1&muted=1}`}
            height="100%"
            frameBorder="0"
            className={`w-full h-full object-cover`}
            allowFullScreen></iframe>
        </div>
      );
    } else if (item?.media !== null && item?.media?.data?.attributes?.ext === '.mp4') {
      return (
        <LazyLoad once className='w-full h-full'>
          <video
            role="presentation"
            crossOrigin="anonymous"
            playsInline={true}
            preload="auto"
            muted={true}
            loop={true}
            width="100%"
            height="100%"
            autoPlay={true}
            className='w-screen h-full object-cover brightness-50'
            src={`${item?.media?.data?.attributes?.url}`}
          >
          </video>
        </LazyLoad>
      );
    } else if (item?.media !== null && item?.media?.data?.attributes?.ext !== '.mp4') {
      return (
        <LazyLoad once className='w-full h-full'>
          <UIImageChooseLink
            data={item?.media?.data?.attributes?.url}
            alt={item?.media?.data?.attributes?.alternativeText}
            style="w-full h-full object-cover"
          />
        </LazyLoad>
      );
    } else {
      return (
        <UINoImage />
      );
    }
  };

  return (
    <div className='w-full h-full'>{showMedia(item)}</div>
  );
};

export default ContactPageMedia;
