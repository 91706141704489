import React, {FC} from 'react';
import {GrAdd} from 'react-icons/gr';
import UIImageChooseLink from './UIImageChooseLink';

type UIZoomModalProps = {
  element?: any;
  changeActive?: any;
}

const UIZoomModal: FC<UIZoomModalProps> = ({element, changeActive}) => {
  return (
    <div
      className={
        'fixed ' +
        'inset-0 ' +
        'bg-gray-600 ' +
        'bg-opacity-50 ' +
        'overflow-y-scroll ' +
        'flex ' +
        'z-50 ' +
        'items-center ' +
        'justify-center ' +
        'h-full ' +
        'w-full'}
    >
      <div className={
        'p-5 border max-w-95p ' +
        'max-h-95p shadow-lg ' +
        'rounded-md overflow-scroll ' +
        'bg-white'}
      >
        <div className="relative">
          <GrAdd className="h-7 w-7 rotate-45 cursor-pointer fixed" onClick={changeActive} />
          <UIImageChooseLink
            data={element.url}
            alt={element.alt}
            style="w-full h-full object-cover"
          />
        </div>
      </div>
    </div>
  );
};

export default UIZoomModal;
