import React from 'react';
import {MdOutlineHideImage} from 'react-icons/md';

const UINoImage = () => {
  return (
    <div className='flex justify-center flex-col items-center h-full text-gray-menu bg-gray-feature'>
      <MdOutlineHideImage className='w-8 h-8'/>
      Bild saknas
    </div>
  );
};

export default UINoImage;
