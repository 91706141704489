import React, {useState} from 'react';
import {Link, NavLink} from 'react-router-dom';
import {motion} from 'framer-motion';
import LazyLoad from 'react-lazyload';
import {GrAdd} from 'react-icons/gr';
import menuElements from '../../utils/menuElements';
import addDefaultSrc from '../../utils/addDefultImageSrc';

import image from '../../assets/menu-img.jpg';

const AppTopBarMenu = () => {
  const [active, setActive] = useState(false);

  const menuToggle = () => {
    setActive(!active);
  };

  return (
    <div className="bg-white flex justify-between items-center w-full relative py-4">
      <Link to="/" className="font-semibold text-xl pl-5 md:pl-10">new nest</Link>
      <div className="fixed right-5 md:right-10 cursor-pointer z-100 md:z-50">
        {active === false ? (
          <GrAdd className="h-7 w-7 cursor-pointer" onClick={menuToggle} />
        ) : (
          <GrAdd className="h-7 w-7 rotate-45 cursor-pointer" onClick={menuToggle} />
        )}
      </div>
      <div
        className={
          `${active === true ? 'h-screen transition-all opacity-100 duration-300' :
          'h-0 transition-all overflow-hidden opacity-0 duration-300'} ` +
          'top-0 left-0 w-screen flex z-30 fixed '}
      >
        <LazyLoad once className='hidden bg-beige-accent w-1/2 h-full md:flex items-center justify-center'>
          <img src={image} alt="menu image" className='w-full h-full object-cover' onError={addDefaultSrc}/>
        </LazyLoad>
        <div className={
          'w-full flex flex-col justify-center font-semibold items-center md:w-1/2 h-full bg-white'
        }>
          <div className='flex flex-col h-full justify-center lg:pr-10'>
            {menuElements.map((el, idx) => (
              <motion.div
                initial={{opacity: 0, y: 20}}
                whileInView={{opacity: [0, 0.3, 0.8, 1], y: 0}}
                viewport={{once: true}}
                transition={{
                  delay: 0.1 * idx,
                  x: {type: 'easeOut', stiffness: 100},
                  default: {duration: 1},
                }} key={idx} className="mb-5">
                <NavLink
                  to={el.link}
                  onClick={menuToggle}
                  className={
                    ({isActive}) => isActive ?
                    'text-beige-accent text-2xl md:text-3xl lg:text-4xl' : 'text-2xl md:text-3xl lg:text-4xl' }
                >
                  {el.name}
                </NavLink>
              </motion.div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppTopBarMenu;
