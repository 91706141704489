/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import axios from 'axios';

const client = axios.create({baseURL: 'https://newnest.azurewebsites.net/api/'});

const PUSH_KEY = process.env.REACT_APP_STRAPI_KEY;
client.interceptors.request.use((cfg) => {
  cfg!.headers!.Authorization = `Bearer ${PUSH_KEY}`;
  return cfg;
});

export default client;
