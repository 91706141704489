import React, {FC} from 'react';
import {motion} from 'framer-motion';

type ObjectPageSmallTextProps = {
  text: string;
}

const ObjectPageSmallText: FC<ObjectPageSmallTextProps> = ({text}) => {
  return (
    <motion.div
      initial={{opacity: 0, y: 20}}
      whileInView={{opacity: [0, 0.3, 0.8, 1], y: 0}}
      viewport={{once: true}}
      transition={{
        delay: 0.2,
        x: {type: 'easeOut', stiffness: 100},
        default: {duration: 1},
      }} className='md:w-2/3 lg:w-1/3 p-8 md:p-12 lg:p-16 flex justify-center items-center mx-auto'>
      <div className='text-15 font-bold text-center' dangerouslySetInnerHTML={{__html: text}}></div>
    </motion.div>
  );
};

export default ObjectPageSmallText;
