import React, {FC} from 'react';

type AccordionHittaHitPartProps = {
  lat: any;
  lon: any;
}

const AccordionHittaHitPart: FC<AccordionHittaHitPartProps> = ({
  lat,
  lon,
}) => {
  return (
    <div className="accordion-item">
      <div className="flex items-center cursor-pointer my-1 md:my-2">
        <a
          className="flex"
          target="_blank"
          rel="noreferrer"
          href={`https://google.com/maps/place/${lat},${lon}/@${lat},${lon},17z/`}>
          <div className='text-xl md:text-3xl mr-4'>+</div>
          <div className='text-xl md:text-3xl'>Hitta hit</div>
        </a>
      </div>
    </div>
  );
};

export default AccordionHittaHitPart;
