import React, {FC} from 'react';

type UICheckboxProps = {
  text: string;
}

const UICheckbox: FC<UICheckboxProps> = ({text}) => {
  return (
    <div className='flex items-center'>
      <input type="checkbox" className='mr-3'/>
      <div>{text}</div>
    </div>
  );
};

export default UICheckbox;
