import React, {FC} from 'react';
import LazyLoad from 'react-lazyload';
import {Media} from '../../types';
import UIImageChooseLink from '../UICommon/UIImageChooseLink';
import UINoImage from '../UICommon/UINoImage';
import FeatureCarousel from './FeatureCarousel';

type FeatureMediaProps = {
  media: Media;
  hideBlend?: boolean;
}

const FeatureMedia: FC<FeatureMediaProps> = ({media, hideBlend}) => {
  if (media.data === null || media?.data.length < 0 || media.data === undefined || media === null) {
    return (
      <UINoImage/>
    );
  }
  return (
    <>
      {media?.data && media?.data?.length === 1 ? (
        <>
          {media?.data[0]?.attributes?.ext === '.mp4' ? (
            <LazyLoad once className='w-full h-full'>
              <video
                role="presentation"
                crossOrigin="anonymous"
                playsInline={true}
                preload="auto"
                muted={true}
                loop={true}
                width="100%"
                height="100%"
                autoPlay={true}
                className={`w-screen h-full max-h-820 object-cover ${!hideBlend && ('brightness-50')}`}
                src={`${media?.data[0]?.attributes.url}`}
              >
              </video>
            </LazyLoad>
          ) : (
            <LazyLoad once className='w-full h-full'>
              <UIImageChooseLink
                data={media?.data[0]?.attributes?.url}
                alt={media?.data[0]?.attributes?.alternativeText}
                isLarge={true}
                style="w-full h-full object-cover"
              />
            </LazyLoad>
          )}
        </>
      ) : (
        <FeatureCarousel media={media}/>
      )}
    </>
  );
};

export default FeatureMedia;
