const menuElements = [
  {
    name: 'Startsida',
    link: '',
  },
  {
    name: 'Våra bostäder',
    link: '/vara-bostader',
  },
  {
    name: 'Vår filosofi',
    link: 'var-filosofi',
  },
  {
    name: 'Kontakt',
    link: 'kontakt',
  },
];

export default menuElements;
