import React from 'react';

const MyNestPage = () => {
  return (
    <div>
      MyNest
    </div>
  );
};

export default MyNestPage;
