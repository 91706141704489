import React from 'react';

const UIBackToTop = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };
  return (
    <div className='pt-6 flex justify-center items-center' onClick={scrollToTop}>
      <div className='border-black w-10 h-10 border-t-2 border-l-2 rotate-45 mt-2 cursor-pointer'></div>
    </div>
  );
};

export default UIBackToTop;
