import React, {FC} from 'react';
import {motion} from 'framer-motion';
import {Feature} from '../../types';
import FeatureButton from './FeatureButton';
import FeatureImage from './FeatureImage';
import FeatureImageURL from './FeatureImageURL';
import FeatureButtonWrapper from './FeatureButtonWrapper';

type FeatureFullProps = {
  details: Feature;
}

const FeatureFull: FC<FeatureFullProps> = ({details}) => {
  const mapTitle = (elem: any) => {
    if (elem.objekt !== undefined && elem.objekt.data !== null && elem.objekt) {
      return (
        <div className={`font-bold text-xl md:text-3xl lg:text-35 pb-1 md:pb-0 max-w-82p ` +
        `${details?.title !== null && details?.title?.variant === 'white' ? 'text-white' : 'text-black'}`}>
          {elem?.objekt?.data?.attributes?.municipality && <div>
            {elem?.objekt?.data?.attributes?.belongsToNewConstruction === '' ? 'nest ' : 'new '}
            {elem?.objekt?.data?.attributes?.municipality}
          </div>}
          {elem?.objekt?.data?.attributes?.address &&
            <div>{elem?.objekt?.data?.attributes?.address}</div>
          }
        </div>
      );
    } else if (elem.nyproduktion !== undefined && elem.nyproduktion.data !== null && elem.nyproduktion) {
      return (
        <div className={`font-bold text-xl md:text-3xl lg:text-35 pb-1 md:pb-0 max-w-82p ` +
        `${details?.title !== null && details?.title?.variant === 'white' ? 'text-white' : 'text-black'}`}>
          {elem?.nyproduktion?.data?.attributes?.municipality && <div>
            new {elem?.nyproduktion?.data?.attributes?.municipality}
          </div>}
          {elem?.nyproduktion?.data?.attributes?.projectName &&
          <div>{elem?.nyproduktion?.data?.attributes?.projectName}</div>}
        </div>
      );
    } else if (elem.artikel !== undefined && elem.artikel.data !== null && elem.artikel) {
      return (
        <div className={`font-bold text-xl md:text-3xl lg:text-35 pb-1 md:pb-0 max-w-82p ` +
        `${details?.title !== null && details?.title?.variant === 'white' ? 'text-white' : 'text-black'}`}>
          {elem?.artikel?.data?.attributes?.municipality && <div>{elem?.artikel?.data?.attributes?.municipality}</div>}
          {elem?.artikel?.data?.attributes?.extraTitle &&
          <div dangerouslySetInnerHTML={{__html: elem?.artikel?.data?.attributes?.extraTitle}}>
          </div>}
        </div>
      );
    } else {
      return (
        <div className={`font-bold text-xl md:text-3xl lg:text-35 pb-1 md:pb-0 max-w-82p ` +
        `${details?.title !== null && details?.title?.variant === 'white' ? 'text-white' : 'text-black'}`}>
          {elem.title.textFirstPart && <div>{elem?.title?.textFirstPart}</div>}
          {elem.title.textSecondPart && <div>{elem?.title?.textSecondPart}</div>}
        </div>
      );
    }
  };

  return (
    <FeatureButtonWrapper
      buttons={details.buttons}
      objekt={details.objekt}
      nyproduktion={details.nyproduktion}
      artikel={details.artikel}
      style='w-full relative max-h-795 my-5 h-96 md:h-530 lg:h-795 min-h-300'>
      {details?.media?.data !== null && <FeatureImage media={details?.media} hasMaxHeight={true}/>}
      {details.media === undefined && <FeatureImageURL images={details?.images} hasMaxHeight={true} />}
      {details?.media?.data === null && (
          details.objekt.data !== null ? (
            <FeatureImageURL
              images={details?.objekt?.data?.attributes?.images}
              isFasadImage={true}
              hasMaxHeight={true}
            />
          ) : details.nyproduktion.data !== null ? (
            <FeatureImageURL
              images={details?.nyproduktion?.data?.attributes?.images}
              isFasadImage={true}
              hasMaxHeight={true}
            />
          ) : details.artikel.data !== null && (
            <FeatureImageURL
              images={details?.artikel?.data?.attributes?.images}
              isFasadImage={true}
              hasMaxHeight={true}
            />
          )
      )}
      <motion.div
        initial={{opacity: 0, y: 20}}
        whileInView={{opacity: [0, 0.3, 0.8, 1], y: 0}}
        viewport={{once: true}}
        transition={{
          delay: 0.5,
          x: {type: 'easeOut', stiffness: 100},
          default: {duration: 1},
        }} className={
          'absolute z-10 w-full h-full left-0 top-0 flex items-end'
        }>
        <div className={
          'flex flex-col py-1 px-8 md:flex-row md:items-center md:justify-around min-w-[300px] md:min-w-[600px] ' +
          ' md:py-2 md:w-11/12 lg:w-4/5 xl:w-3/4 md:px-0 mb-8 lg:min-w-[800px] xl:min-w-[1000px] 2xl:min-w-[1200px] ' +
          `${details?.title !== null && details?.title?.variant === 'backdrop' ? 'bg-whiteTransparent text-black' :
          'text-white bg-transparent'} `}>
          {mapTitle(details)}
          <div
            className={`${details?.title !== null && details?.title?.variant === 'white' ? 'text-white' :
            'text-black'}`}>
            <FeatureButton
              buttons={details.buttons}
              objekt={details.objekt}
              nyproduktion={details.nyproduktion}
              artikel={details.artikel}/>
          </div>
        </div>
      </motion.div>
    </FeatureButtonWrapper>
  );
};

export default FeatureFull;
