import React, {FC} from 'react';
import {motion} from 'framer-motion';

type ObjectPageMainTextProps = {
  title: string;
  description: string;
}

const ObjectPageMainText: FC<ObjectPageMainTextProps> = ({title, description}) => {
  return (
    <div className='md:w-2/3 p-8 md:p-12 lg:p-16'>
      {title && (
        <motion.div
          initial={{opacity: 0, y: 20}}
          whileInView={{opacity: [0, 0.3, 0.8, 1], y: 0}}
          viewport={{once: true}}
          transition={{
            delay: 0.3,
            x: {type: 'easeOut', stiffness: 100},
            default: {duration: 1},
          }} className='text-xl md:text-3xl lg:text-35 font-bold pb-5'
          dangerouslySetInnerHTML={{__html: title}}></motion.div>)}
      {description && (
        <motion.div
          initial={{opacity: 0, y: 20}}
          whileInView={{opacity: [0, 0.3, 0.8, 1], y: 0}}
          viewport={{once: true}}
          transition={{
            delay: 0.6,
            x: {type: 'easeOut', stiffness: 100},
            default: {duration: 1},
          }} className='text-11 md:text-15' dangerouslySetInnerHTML={{__html: description}}></motion.div>)}
    </div>
  );
};

export default ObjectPageMainText;
