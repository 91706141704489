import React, {FC} from 'react';
import LazyLoad from 'react-lazyload';
import UIImageChooseLink from '../UICommon/UIImageChooseLink';
import UINoImage from '../UICommon/UINoImage';

type FeatureImageProps = {
  media: any;
  hasMaxHeight?: boolean;
}

const FeatureImage: FC<FeatureImageProps> = ({media, hasMaxHeight}) => {
  if (media.data.length < 0 || media?.data === null) {
    return (
      <UINoImage />
    );
  }
  return (
    <LazyLoad once className='w-full h-full'>
      <UIImageChooseLink
        data={media?.data[0]?.attributes?.url}
        alt={media?.data[0]?.attributes?.alternativeText}
        isLarge={true}
        style={`w-full h-full object-cover ${hasMaxHeight && 'max-h-[90vh]'}`}
      />
    </LazyLoad>
  );
};

export default FeatureImage;
