import React, {Suspense, useEffect} from 'react';
import {Routes, Route, useLocation} from 'react-router-dom';
import {footerApi, footerLinksApi} from '../../api';
import AppTopBarMenu from './AppTopBarMenu';
import AppFooter from './AppFooter';
import HomePage from '../../pages/HomePage';
import MyNestPage from '../../pages/MyNestPage';
import NewNestPage from '../../pages/NewNestPage';
import OurPhilosophyPage from '../../pages/OurPhilosophyPage';
import ContactPage from '../../pages/ContactPage';
import ArticlePage from '../../pages/ArticlePage';
import ObjectPage from '../../pages/ObjectPage';
import NewProductionPage from '../../pages/NewProductionPage';
import UILoader from '../UICommon/UILoader';
import GalleryPage from '../../pages/GalleryPage';
import GalleryObjectPage from '../../pages/GalleryObjectPage';
import GalleryArticlePage from '../../pages/GalleryArticlePage';
import UICookieBanner from '../UICommon/UICookieBanner';
import CookiesPage from '../../pages/CookiesPage';

const footerResource = footerApi.fetchData();
const footerLinksResource = footerLinksApi.fetchData();

const AppLayout = () => {
  const footerData = footerResource.content.read();
  const footerLinksData = footerLinksResource.content.read();
  const currentLocation = useLocation();

  useEffect(() => {
    if (currentLocation) {
      document.body.style.overflow = 'auto';
    }
  }, [currentLocation]);

  return (
    <div>
      <div>
        <AppTopBarMenu />
      </div>
      <div>
        <Routes>
          <Route path="/" element={
            <Suspense fallback={<UILoader/>}><HomePage /></Suspense>}/>
          <Route path="/vara-bostader" element={
            <Suspense fallback={<UILoader/>}><NewNestPage /></Suspense>}/>
          <Route path="/my-nest" element={
            <Suspense fallback={<UILoader/>}><MyNestPage /></Suspense>}/>
          <Route path="/var-filosofi" element={
            <Suspense fallback={<UILoader/>}><OurPhilosophyPage /></Suspense>}/>
          <Route path="/kontakt" element={
            <Suspense fallback={<UILoader/>}><ContactPage /></Suspense>}/>
          <Route path="/artikel/:id" element={
            <Suspense fallback={<UILoader/>}><ArticlePage /></Suspense>}/>
          <Route path="/artikel/:id/gallery" element={
            <Suspense fallback={<UILoader/>}><GalleryArticlePage /></Suspense>}/>
          <Route path="/objekt/:id" element={
            <Suspense fallback={<UILoader/>}><ObjectPage /></Suspense>}/>
          <Route path="/objekt/:id/gallery" element={
            <Suspense fallback={<UILoader/>}><GalleryObjectPage /></Suspense>}/>
          <Route path="/nyproduktion/:id" element={
            <Suspense fallback={<UILoader/>}><NewProductionPage /></Suspense>}/>
          <Route path="/nyproduktion/:id/gallery" element={
            <Suspense fallback={<UILoader/>}><GalleryPage /></Suspense>}/>
          <Route path="/kakor" element={
            <Suspense fallback={<UILoader/>}><CookiesPage /></Suspense>}/>
        </Routes>
      </div>
      <div className='bg-gray-footer md:mt-10 text-white h-470 lg:h-716'>
        <AppFooter footerDetails={footerData?.attributes} footerLinksDetails={footerLinksData?.attributes?.button}/>
      </div>
      <div className='max-w-1420 mx-auto'>
        <div className='fixed bottom-6 z-30 md:bottom-16 lg:bottom-6 xl:bottom-6 w-full'>
          <UICookieBanner
            cookieDescription={
              'Vi använder cookies för att anpassa din upplevelse och analysera trafiken på webbplatsen.'}
            cookieText={'Acceptera'}
            cookieReadMore={'Läs mer'}
          />
        </div>
      </div>
    </div>
  );
};

export default AppLayout;
