/* eslint-disable */
import client from './client';
import {HomePage} from '../types';

async function fetchFooterLinks() : Promise<HomePage> {
  const res = await client.get(`footer-link?populate=*`);
  return res.data.data;
}

function fetchData() {
  const contentPromise = fetchFooterLinks();
  return {
    content: wrapPromise(contentPromise),
  };
}

function wrapPromise(promise : any) {
  let status = 'pending';
  let result : any;
  let suspender = promise.then(
    (r: any) => {
      status = "success";
      result = r;
    },
    (e: any) => {
      status = "error";
      result = e;
    }
  );
  return {
    read() {
      if (status === "pending") {
        throw suspender;
      } else if (status === "error") {
        throw result;
      } else if (status === "success") {
        return result;
      }
    }
  };
}

export default {
  fetchData,
};
