import React, {FC} from 'react';
import LazyLoad from 'react-lazyload';
import {GrAdd} from 'react-icons/gr';
import {MdArrowForwardIos, MdArrowBackIos} from 'react-icons/md';
import UIImageChooseLink from './UIImageChooseLink';

type UIZoomModalCarouselProps = {
  element?: any;
  changeActive?: any;
  prevSlide: any;
  nextSlide: any;
}

const UIZoomModalCarousel: FC<UIZoomModalCarouselProps> = ({element, changeActive, prevSlide, nextSlide}) => {
  return (
    <div
      className={
        'fixed ' +
        'inset-0 ' +
        'bg-gray-600 ' +
        'bg-opacity-50 ' +
        'overflow-y-scroll ' +
        'flex ' +
        'z-50 ' +
        'items-center ' +
        'justify-center ' +
        'h-full ' +
        'w-full'}
    >
      <div className={
        'p-5 border max-w-95p ' +
        'max-h-95p shadow-lg ' +
        'rounded-md overflow-scroll ' +
        'bg-white'}
      >
        <div className="relative">
          <MdArrowBackIos
            className={`left-4 md:left-15p text-black fixed text-3xl cursor-pointer z-10 top-50p`}
            onClick={prevSlide}/>
          <MdArrowForwardIos
            className={`right-4 md:right-15p text-black fixed text-3xl cursor-pointer z-10 top-50p`}
            onClick={nextSlide}/>
          <GrAdd className="h-7 w-7 rotate-45 cursor-pointer fixed" onClick={changeActive} />
          <LazyLoad once className='w-full h-full'>
            <UIImageChooseLink
              data={element.url}
              alt={element.alt}
              style="w-full h-full object-cover"
            />
          </LazyLoad>
        </div>
      </div>
    </div>
  );
};

export default UIZoomModalCarousel;
