import React, {FC} from 'react';
import {GoPlus} from 'react-icons/go';
import {Buttons} from '../../types';
import {
  Link,
} from 'react-router-dom';

type FeatureButtonProps = {
  buttons: Buttons;
  objekt?: any;
  nyproduktion?: any;
  artikel?: any;
}

const FeatureButton: FC<FeatureButtonProps> = ({buttons, objekt, nyproduktion, artikel}) => {
  if (objekt !== undefined && objekt.data !== null && objekt) {
    return (
      <Link
        to={`/objekt/${objekt?.data?.attributes?.fasadID}`}
        className="text-9 md:text-11 flex font-bold items-center pr-5 md:pr-10"
      > LÄS MER
        <GoPlus className="h-4 w-4 ml-4 mb-0.5"/>
      </Link>
    );
  } else if (nyproduktion !== undefined && nyproduktion.data !== null && nyproduktion) {
    return (
      <Link
        to={`/nyproduktion/${nyproduktion?.data?.attributes?.fasadID}`}
        className="text-9 md:text-11 flex font-bold items-center pr-5 md:pr-10"
      > LÄS MER
        <GoPlus className="h-4 w-4 ml-4 mb-0.5"/>
      </Link>
    );
  } else if (artikel !== undefined && artikel.data !== null && artikel) {
    return (
      <Link
        to={`/artikel/${artikel?.data?.attributes?.fasadID}`}
        className="text-9 md:text-11 flex font-bold items-center pr-5 md:pr-10"
      > LÄS MER
        <GoPlus className="h-4 w-4 ml-4 mb-0.5"/>
      </Link>
    );
  } else {
    return (
      <>
        {buttons && buttons.map((el: any, idx) => (
          <Link
            key={idx}
            to={el.buttonLink}
            className="text-9 md:text-11 flex font-bold items-center pr-5 md:pr-10"
          >{el.buttonText}
            <GoPlus className="h-4 w-4 ml-4 mb-0.5"/>
          </Link>
        ))}
      </>
    );
  }
};

export default FeatureButton;
