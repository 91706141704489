import React, {FC} from 'react';
import {GoPlus} from 'react-icons/go';
import {Button} from '../../types';

type UIButtonProps = {
  button: Button;
}

const UIButton: FC<UIButtonProps> = ({button}) => {
  return (
    <a
      href={button?.buttonLink}
      className="text-9 md:text-11 flex font-bold items-center pr-5 md:pr-10"
    >{button?.buttonText}
      <GoPlus className="h-4 w-4 ml-4 mb-0.5"/>
    </a>
  );
};

export default UIButton;
