import React, {FC} from 'react';
import {Link} from 'react-router-dom';
import {GoPlus} from 'react-icons/go';

type UIGoToGalleryButtonProps = {
  id: any;
}

const UIGoToGalleryButton: FC<UIGoToGalleryButtonProps> = ({id}) => {
  return (
    <Link
      to={`/nyproduktion/${id}/gallery`}
      className='flex flex-col justify-center items-center font-bold py-5 cursor-pointer'>
      <GoPlus className="h-4 w-4 mb-0.5 font-bold text-7xl"/>
      <div>alla bilder</div>
    </Link>
  );
};

export default UIGoToGalleryButton;
