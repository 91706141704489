import React, {FC, useState} from 'react';
import LazyLoad from 'react-lazyload';
import {MdArrowForwardIos, MdArrowBackIos} from 'react-icons/md';
import {BsZoomIn} from 'react-icons/bs';
import UINoImage from '../UICommon/UINoImage';
import UIZoomModalCarousel from '../UICommon/UIZoomModalCarousel';
import UIImageChooseLink from '../UICommon/UIImageChooseLink';

type FeatureCarouselURLProps = {
  images: [{alt: string, title: string, url: string, id: number}];
  isBlueprint?: boolean;
}

const FeatureCarouselURL: FC<FeatureCarouselURLProps> = ({images, isBlueprint}) => {
  const [current, setCurrent] = useState(0);
  const [active, setActive] = useState(false);
  const sliderLength = images.length;

  const nextSlide = () => {
    setCurrent(current === sliderLength - 1 ? 0 : current + 1);
  };

  const prevSlide = () => {
    setCurrent(current === 0 ? sliderLength - 1 : current - 1);
  };

  const changeActive = () => {
    if (active === false) {
      setActive(!active);
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
      setActive(!active);
    }
  };

  return (
    <>
      {(Object.keys(images).length === 0) ? (
        <UINoImage />
      ) : (
        <div className='relative flex justify-center items-center h-full'>
          <MdArrowBackIos
            className={
              `${isBlueprint ? '-left-6 lg:-left-8 text-black' : 'left-4 md:left-10 text-white'}` +
              ' absolute text-3xl cursor-pointer z-0 '}
            onClick={prevSlide}
          />
          <MdArrowForwardIos
            className={
              `${isBlueprint ? '-right-8 lg:-right-10 text-black' : 'right-4 md:right-10 text-white'}` +
              ` absolute text-3xl cursor-pointer z-0`}
            onClick={nextSlide}
          />
          {images && images.map((el : any, idx : any) => (
            <div
              key={idx}
              className={idx === current ? 'transition-all opacity-100 duration-300 h-full w-full object-cover' :
              'transition-all opacity-70 duration-300 h-full object-cover relative'}
            >
              {idx === current && (
                <LazyLoad once className='w-full h-full'>
                  {isBlueprint ? (
                    <UIImageChooseLink
                      data={el?.url}
                      alt={el?.alt}
                      style="w-full h-full object-cover"
                    />
                  ) : (
                    <UIImageChooseLink
                      data={el?.url}
                      alt={el?.alt}
                      isLarge={true}
                      style="w-full h-full object-cover"
                    />
                  )}
                </LazyLoad>
              )}
              {active && (
                <UIZoomModalCarousel
                  element={el}
                  changeActive={changeActive}
                  prevSlide={prevSlide}
                  nextSlide={nextSlide}/>
              )}
            </div>
          ))}
        </div>
      )}
      {isBlueprint && (
        <BsZoomIn
          onClick={changeActive}
          className='w-6 h-6 lg:w-8 lg:h-8 absolute right-5 bottom-4 lg:right-20 lg:bottom-14 cursor-pointer'
        />
      )}
    </>
  );
};

export default FeatureCarouselURL;
