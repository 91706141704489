import React, {FC} from 'react';
import {GoPlus} from 'react-icons/go';
import {motion} from 'framer-motion';
import {Hero} from '../../types';
import UISingleMedia from '../UICommon/UISingleMedia';
import FeatureMediaURL from '../Feature/FeatureMediaURL';


type HeroFullProps = {
  details: Hero;
  type?: 'normal' | 'background';
  images?: any;
  scrollToDisplay?: any
  button?: {text: string, url: string};
}

const HeroFull: FC<HeroFullProps> = ({details, type='normal', button, images, scrollToDisplay}) => {
  let segments = [];
  let videoId = '';
  if (details.startFilm != null && details.startFilm?.length > 0 ) {
    segments = details.startFilm.split('/');
    videoId = segments[segments.length - 1];
  }
  return (
    <div className='relative h-screenWithoutNav mb-16'>
      {videoId !== '' ? (
        <div className="w-full h-full overflow-hidden vimeo-video-wrapper-full relative">
          <iframe
            title={'title'}
            // eslint-disable-next-line max-len
            src={`https://player.vimeo.com/video/${videoId}?speed=0&autoplay=1&controls=0&background=1&quality=720p&loop=1&muted=1}`}
            height="100%"
            frameBorder="0"
            className={`w-full h-full object-cover`}
            allowFullScreen></iframe>
        </div>
      ) : (
        <>
          {(details.media && !images) && <UISingleMedia media={details.media}/>}
          {images && <FeatureMediaURL images={images} />}
        </>
      )}
      <div className={
        `absolute text-white z-10 justify-center flex first-line:md:px-20 ${type === 'background' ?
        `bottom-0 left-0 right-0 items-center md:bottom-[-45px]
         md:right-[10%] md:items-start md:justify-end` :
        'left-5 bottom-0 h-full items-center md:items lg:w-3/4'}`}>
        <div className={`${type === 'background' ?
        'bg-beige-background w-full h-1/2 px-[10px] py-[15px] md:w-[358px] md:h-[358px] md:py-[40px] md:px-[25px]' :
         ''}`}>
          {details.header && (
            <motion.div
              initial={{opacity: 0, x: -50}}
              whileInView={{opacity: 1, x: 0}}
              viewport={{once: true}}
              transition={{
                delay: 0.1,
                x: {type: 'easeOut', stiffness: 100},
                default: {duration: 0.8},
              }}
              className={`pl-5 pb-3 font-bold
              ${type === 'background' ? `text-[23px] leading-[27px] font-bold md:text-[25px]
              md:leading-[32px] text-black` :
              'text-5xl md:text-6xl lg:pb-8'}`}>
              {details.header}</motion.div>
          )}
          {details.description && (
            <motion.div
              initial={{opacity: 0, y: 100}}
              whileInView={{opacity: 1, y: 0}}
              viewport={{once: true}}
              transition={{
                delay: 0.4,
                x: {type: 'easeOut', stiffness: 100},
                default: {duration: 0.8},
              }}
              className={
                `font-bold pr-10 pl-5
                ${type === 'background' ? 'text-[13px] md:text-[15px] text-black break-normal' :
                'lg:w-800 text-lg md:text-3xl lg:text-40 md:font-normal leading-6 lg:leading-12'}`
              }>
              {(details.subheader && type === 'background') && (
                <p className='pb-3 text-[13px] md:text-[15px]'>{details.subheader}</p>
              )}
              <p>{details.description}</p>
              {(type === 'background' && scrollToDisplay) && (
                <div onClick={scrollToDisplay} className='flex items-center cursor-pointer pt-3'>
                  <p className='text-[13px] md:text-[15px] font-semibold'>Info om visning</p>
                  <GoPlus className="h-4 w-4 ml-2"/>
                </div>
              )}
            </motion.div>
          )}
          {button?.text && (
            <motion.div
              initial={{opacity: 0, y: 100}}
              whileInView={{opacity: 1, y: 0}}
              viewport={{once: true}}
              transition={{
                delay: 0.6,
                x: {type: 'easeOut', stiffness: 100},
                default: {duration: 0.8},
              }}
              className={`lg:w-800 font-bold text-lg md:text-3xl leading-6 pr-10 pl-5
                pt-4 lg:pt-8 lg:text-40 lg:leading-12 md:font-normal`
              }>
              <a
                href={`/${button?.url}`}
                className="border-b lg:border-b-2 pb-1 border-white inline"
              >
                {button?.text}
                <GoPlus className="h-4 w-4 ml-4 mb-0.5 inline"/>
              </a>
            </motion.div>
          )}
        </div>
      </div>
    </div>
  );
};

export default HeroFull;
