import React, {useEffect, useState} from 'react';
import {Link, useParams} from 'react-router-dom';
import {objectPageApi} from '../api';
import UILoader from '../components/UICommon/UILoader';
import UIGalleryImage from '../components/UICommon/UIGalleryImage';

const GalleryObjectPage = () => {
  const {id} = useParams();
  const [data, setData] = useState<any>();

  useEffect(() => {
    objectPageApi.fetchData(id).then((res) => {
      setData(res);
    });
  }, []);

  return (
    <div className='lg:my-20 max-w-5xl mx-auto px-5 md:px-10'>
      {data ? (
        <>
          <Link to={`/objekt/${id}`} className='underline font-bold'>Tillbaka</Link>
          <div className='mx-auto'>
            {data[0]?.attributes?.images.map((el: any, idx: number) => (
              <UIGalleryImage data={el} key={idx}/>
            ))}
          </div>
          <div className='flex justify-center items-center'>
            <Link to={`/objekt/${id}`} className='underline font-bold my-10'>Tillbaka</Link>
          </div>
        </>
      ) : (
        <UILoader />
      )}
    </div>
  );
};

export default GalleryObjectPage;
