/* eslint-disable max-len */
import React, {FC} from 'react';
import {motion} from 'framer-motion';

type NewProductionPageTableProps = {
  objectsForTable: any;
  handleClick: Function;
}

const NewProductionPageTable: FC<NewProductionPageTableProps> = ({objectsForTable, handleClick}) => {
  function changeWord(word: string) {
    if (word === 'for sale') {
      return <span>Till salu</span>;
    } else if (word === 'sold') {
      return <span>såld</span>;
    } else if (word === 'upcoming') {
      return <span>Kommande</span>;
    } else {
      return <span>{word}</span>;
    }
  }

  const showPrice = (price: any) => {
    if (price?.includes('null')) {
      return price?.replace('null', '-');
    } else if (price === '') {
      return '-';
    } else {
      return price?.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    }
  };

  return (
    <motion.div
      initial={{opacity: 0.6}}
      whileInView={{opacity: 1}}
      viewport={{once: true}}
      transition={{
        delay: 0.2,
        x: {type: 'easeOut', stiffness: 100},
        default: {duration: 0.4},
      }} className='w-full py-8 lg:py-24 max-w-max overflow-x-scroll md:overflow-x-hidden mx-auto md:max-w-none'>
      <table className="text-15 text-left text-black w-680 md:w-full">
        <thead className="bg-white border-b-2 font-bold text-md light:bg-gray-800 light:border-black text-center">
          <tr className='text-8 md:text-11'>
            <th className="p-4">Objekt</th>
            <th className="p-4">Boarea</th>
            <th className="p-4">Tomtarea</th>
            <th className="p-4">Antal rum</th>
            <th className="p-4">Avgift</th>
            <th className="p-4">Pris</th>
            <th className="p-4">Dokument</th>
            <th className="p-4">Status</th>
          </tr>
        </thead>
        <tbody>
          {objectsForTable?.sort((a: any, b: any) => a?.attributes?.facts?.apartmentNumber?.localeCompare(b.attributes?.facts?.apartmentNumber))
              .map((item: any, index: number) => {
                return (
                  <motion.tr
                    className={`${(item?.attributes?.status === 'sold') ? 'opacity-30' : ''} ` +
                      ' bg-white border-b-2 light:bg-gray-800 light:border-black text-center ' +
                      'cursor-pointer font-semibold text-8 md:text-11'}
                    onClick={() => handleClick(item.attributes.fasadID)}
                    key={index}
                    initial={{opacity: 0}}
                    whileInView={{opacity: [0, 0.3, 0.8, 1]}}
                    viewport={{once: true}}
                    transition={{
                      delay: 0.3 * index,
                      x: {type: 'easeOut', stiffness: 100},
                      default: {duration: 1},
                    }}>
                    <td className="py-3 px-4">{item?.attributes?.facts?.apartmentNumber}</td>
                    <td className="py-3 px-4">{item?.attributes?.size}</td>
                    <td className="py-3 px-4">{item?.attributes?.facts?.plot !== null &&
                  item?.attributes?.facts?.plot.length > 0 ? item?.attributes?.facts?.plot : '-'}</td>
                    <td className="py-3 px-4">{item?.attributes?.numberOfRooms}</td>
                    <td className="py-3 px-4">{item?.attributes?.fee}</td>
                    <td className="py-3 px-4">{showPrice(item?.attributes?.price)}
                    </td>
                    <td className="py-3 px-4 font-semibold">+</td>
                    <td className="py-3 px-4">{changeWord(item?.attributes?.status)}</td>
                  </motion.tr>
                );
              },
              )}

        </tbody>
      </table>

    </motion.div>
  );
};

export default NewProductionPageTable;
