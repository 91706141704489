/* eslint-disable */
import client from './client';
import {Feature} from '../types';

const qs = require('qs');
const query = qs.stringify({
  populate: {
    attributes: {
      populate: ['*'],
    },
    images: {
      populate: ['*'],
    },
    showings: {
      populate: ['*'],
    },
    facts: {
      populate: ['*'],
    },
  } 
}, {
  encodeValuesOnly: true,
});

async function fetchObjectList() : Promise<Feature> {
  const res = await client.get(`objekts?${query}`);
  return res.data.data;
}

function fetchData() {
  const contentPromise = fetchObjectList();
  return {
    content: wrapPromise(contentPromise),
  };
}

function wrapPromise(promise : any) {
  let status = 'pending';
  let result : any;
  let suspender = promise.then(
    (r: any) => {
      status = "success";
      result = r;
    },
    (e: any) => {
      status = "error";
      result = e;
    }
  );
  return {
    read() {
      if (status === "pending") {
        throw suspender;
      } else if (status === "error") {
        throw result;
      } else if (status === "success") {
        return result;
      }
    }
  };
}

export default {
  fetchData,
};
