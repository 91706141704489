import React, {FC} from 'react';
import LazyLoad from 'react-lazyload';
import {SingleMedia} from '../../types';
import UIImageChooseLink from './UIImageChooseLink';
import UINoImage from './UINoImage';

type UISingleMediaProps = {
  media: SingleMedia;
  style?: string;
  hideBlend?: boolean;
}

const UISingleMedia: FC<UISingleMediaProps> = ({media, style, hideBlend}) => {
  if (Object.keys(media).length === 0 || media?.data === undefined || media?.data === null) {
    return (
      <UINoImage />
    );
  }
  if (media.data.attributes.hasOwnProperty('createdAt')) {
    return (
      <div className='-z-50 h-full w-full'>
        {media?.data?.attributes?.ext === '.mp4' ? (
          <LazyLoad once className='w-full h-full'>
            <video
              role="presentation"
              crossOrigin="anonymous"
              playsInline={true}
              preload="auto"
              muted={true}
              loop={true}
              width="100%"
              height="100%"
              autoPlay={true}
              className={`w-screen h-full object-cover ${!hideBlend && ('brightness-50')}  ${style}`}
              src={`${media?.data?.attributes.url}`}
            >
            </video>
          </LazyLoad>
        ) : (
          <LazyLoad once className='w-full h-full'>
            <UIImageChooseLink
              data={media?.data?.attributes.url}
              style={`w-full h-full object-cover ${style}`}
            />
          </LazyLoad>
        )}
      </div>
    );
  }
  return (
    <div className='-z-50 h-full w-full'>
      {media?.data?.attributes?.ext === '.mp4' ? (
        <LazyLoad once className='w-full h-full'>
          <video
            role="presentation"
            crossOrigin="anonymous"
            playsInline={true}
            preload="auto"
            muted={true}
            loop={true}
            width="100%"
            height="100%"
            autoPlay={true}
            className={`w-screen h-full max-h-820 object-cover ${!hideBlend && ('brightness-50')} ${style}`}
            src={`${media?.data?.attributes.url}`}
          >
          </video>
        </LazyLoad>
      ) : (
        <LazyLoad once className='w-full h-full'>
          <UIImageChooseLink
            data={media?.data?.attributes.url}
            alt={media?.data?.attributes?.alternativeText}
            style={`w-full h-full object-cover ${style}`}
          />
        </LazyLoad>
      )}
    </div>
  );
};

export default UISingleMedia;
