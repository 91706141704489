import React, {FC} from 'react';
import {motion} from 'framer-motion';
import LazyLoad from 'react-lazyload';
import {Feature} from '../../types';
import FeatureButton from './FeatureButton';
import FeatureButtonWrapper from './FeatureButtonWrapper';
import UIImageChooseLink from '../UICommon/UIImageChooseLink';

type FeatureCollageProps = {
  details: Feature
}

const FeatureCollage: FC<FeatureCollageProps> = ({details}) => {
  const mapTitle = (elem: any) => {
    if (elem.objekt !== undefined && elem.objekt.data !== null && elem.objekt) {
      return (
        <>
          {elem?.objekt?.data?.attributes?.municipality && <div>
            {elem?.objekt?.data?.attributes?.belongsToNewConstruction === '' ? 'nest ' : 'new '}
            {elem?.objekt?.data?.attributes?.municipality}
          </div>}
          {elem?.objekt?.data?.attributes?.address &&
            <div>{elem?.objekt?.data?.attributes?.address}</div>
          }
        </>
      );
    } else if (elem.nyproduktion !== undefined && elem.nyproduktion.data !== null && elem.nyproduktion) {
      return (
        <>
          {elem?.nyproduktion?.data?.attributes?.municipality && <div>
            new {elem?.nyproduktion?.data?.attributes?.municipality}
          </div>}
          {elem?.nyproduktion?.data?.attributes?.projectName &&
          <div>{elem?.nyproduktion?.data?.attributes?.projectName}</div>}
        </>
      );
    } else if (elem.artikel !== undefined && elem.artikel.data !== null && elem.artikel) {
      return (
        <>
          {elem?.artikel?.data?.attributes?.municipality && <div>{elem?.artikel?.data?.attributes?.municipality}</div>}
          {elem?.artikel?.data?.attributes?.extraTitle &&
          <div dangerouslySetInnerHTML={{__html: elem?.artikel?.data?.attributes?.extraTitle}}></div>}
        </>
      );
    } else {
      return (
        <>
          {elem?.details?.title?.textFirstPart && (<div>{elem?.details?.title?.textFirstPart}</div>)}
          {elem?.details?.title?.textSecondPart && (<div>{elem?.details?.title?.textSecondPart}</div>)}
        </>
      );
    }
  };

  const mapSubheader = (elem: any) => {
    if (elem.objekt !== undefined && elem.objekt.data !== null && elem.objekt) {
      const objekt = elem?.objekt?.data?.attributes;
      return (objekt?.facts?.price ? objekt?.facts?.price.replace(/\B(?=(\d{3})+(?!\d))/g, ' ') + ' / ' : '') +
      (objekt?.facts?.boarea ? 'Boarea ' + objekt?.facts?.boarea +' / ' : '') +
      (objekt?.numberOfRooms ? objekt?.numberOfRooms + ' / ' : '') +
      (objekt?.facts?.fee ? objekt?.facts?.fee + ' / ' : '') +
      (objekt?.facts?.plot ? 'Tomt ' + objekt?.facts?.plot : '');
    } else if (elem.nyproduktion !== undefined && elem.nyproduktion.data !== null && elem.nyproduktion) {
      return elem.nyproduktion?.data?.attributes?.kortFakta;
    } else if (elem.artikel !== undefined && elem.artikel.data !== null && elem.artikel) {
      return null;
    } else {
      return elem?.subheader?.replaceAll('null', '-');
    }
  };

  const mapDescription = (elem: any) => {
    if (elem?.artikel?.data !== null && elem?.artikel) {
      return elem?.artikel?.data?.attributes?.Title;
    } else if (elem?.objekt !== undefined && elem?.objekt?.data !== null && elem?.objekt) {
      return elem?.objekt?.data?.attributes?.salesTitle;
    } else if (elem?.nyproduktion !== undefined && elem?.nyproduktion?.data !== null && elem?.nyproduktion) {
      return elem?.nyproduktion?.data?.attributes?.salesTitle;
    } else if (elem?.artikel !== undefined && elem?.artikel?.data !== null && elem?.artikel) {
      return elem?.artikel?.data?.attributes?.Title;
    } else {
      return elem?.description;
    }
  };
  return (
    <div className='flex xl:min-h-700 pb-10 mt-12 h-80 md:h-450 lg:h-600 xl:h-730'>
      <div className='w-1/2 md:w-1/2 relative flex lg:justify-end items-end justify-center md:mr-10 '>
        {details?.media?.data !== null && (
          <>
            {details && details?.media?.data.map((el, idx) => {
              if (idx === 0) {
                return (
                  <div
                    key={idx}
                    className={
                      'absolute -rotate-6 md:pl-10 md:-z-10 bottom-20 left-5 xl:left-5 -z-10 h-44 ' +
                      'md:h-72 lg:h-470 xl:h-600 mr-10'
                    }>
                    <LazyLoad once className='w-full h-full object-cover'>
                      <UIImageChooseLink
                        data={el?.attributes?.url}
                        alt={el?.attributes?.alternativeText}
                        isLarge={true}
                        style="w-full h-full object-cover"
                      />
                    </LazyLoad>
                  </div>
                );
              }
              return (
                <div
                  key={idx}
                  className='w-11/12 md:w-1/2 z-20 max-h-370'>
                  <LazyLoad once className='w-full h-full object-cover'>
                    <UIImageChooseLink
                      data={el?.attributes?.url}
                      alt={el?.attributes?.alternativeText}
                      isLarge={true}
                      style="w-full h-full object-cover"
                    />
                  </LazyLoad>
                </div>
              );
            })}
          </>
        )}
        {details.media === undefined && (
          <>
            {details && details?.images?.map((el, idx) => {
              if (idx === 0) {
                return (
                  <div
                    key={idx}
                    className={
                      'absolute -rotate-6 md:pl-10 md:-z-10 bottom-20 left-5 xl:left-5 -z-10 h-44 ' +
                      'md:h-72 lg:h-470 xl:h-600 mr-10'
                    }>
                    <LazyLoad once className='w-full h-full object-cover'>
                      <UIImageChooseLink
                        data={el?.url}
                        alt={el.alt}
                        isLarge={true}
                        style="w-full h-full object-cover"
                      />
                    </LazyLoad>
                  </div>
                );
              }
              return (
                <div
                  key={idx}
                  className='w-11/12 md:w-3/4 z-20 max-h-350'>
                  <LazyLoad once className='w-full h-full object-cover'>
                    <UIImageChooseLink
                      data={el?.url}
                      alt={el.alt}
                      isLarge={true}
                      style="w-full h-full object-cover"
                    />
                  </LazyLoad>
                </div>
              );
            })}</>
        ) }
        {details?.media?.data === null && (
          details.objekt.data !== null ? (
            <>
              <div
                className={
                  'absolute -rotate-6 md:pl-10 md:-z-10 bottom-20 left-5 xl:left-5 -z-10 h-44 ' +
                  'md:h-72 lg:h-470 xl:h-600 mr-10'
                }>
                <LazyLoad once className='w-full h-full object-cover'>
                  <UIImageChooseLink
                    data={details?.objekt?.data?.attributes?.images[0]?.url}
                    alt={details?.objekt?.data?.attributes?.images[0]?.alt}
                    isLarge={true}
                    style="w-full h-full object-cover"
                  />
                </LazyLoad>
              </div>
              <div
                className='w-11/12 md:w-3/4 z-20 max-h-350'>
                <LazyLoad once className='w-full h-full object-cover'>
                  <UIImageChooseLink
                    data={details?.objekt?.data?.attributes?.images[1]?.url}
                    alt={details?.objekt?.data?.attributes?.images[1]?.alt}
                    isLarge={true}
                    style="w-full h-full object-cover"
                  />
                </LazyLoad>
              </div>
            </>
          ) : details.nyproduktion.data !== null ? (
            <>
              <div
                className={
                  'absolute -rotate-6 md:pl-10 md:-z-10 bottom-20 left-5 xl:left-5 -z-10 h-44 ' +
                  'md:h-72 lg:h-470 xl:h-600 mr-10'
                }>
                <LazyLoad once className='w-full h-full object-cover'>
                  <UIImageChooseLink
                    data={details?.nyproduktion?.data?.attributes?.images[0]?.url}
                    alt={details?.nyproduktion?.data?.attributes?.images[0]?.alt}
                    isLarge={true}
                    style="w-full h-full object-cover"
                  />
                </LazyLoad>
              </div>
              <div
                className='w-11/12 md:w-3/4 z-20 max-h-350'>
                <LazyLoad once className='w-full h-full object-cover'>
                  <UIImageChooseLink
                    data={details?.nyproduktion?.data?.attributes?.images[1]?.url}
                    alt={details?.nyproduktion?.data?.attributes?.images[1]?.alt}
                    isLarge={true}
                    style="w-full h-full object-cover"
                  />
                </LazyLoad>
              </div>
            </>
          ) : details.artikel.data !== null && (
            <>
              <div
                className={
                  'absolute -rotate-6 md:pl-10 md:-z-10 bottom-20 left-5 xl:left-5 -z-10 h-44 ' +
                  'md:h-72 lg:h-470 xl:h-600 mr-10'
                }>
                <LazyLoad once className='w-full h-full object-cover'>
                  <UIImageChooseLink
                    data={details?.artikel?.data?.attributes?.images[0]?.url}
                    alt={details?.artikel?.data?.attributes?.images[0]?.alt}
                    isLarge={true}
                    style="w-full h-full object-cover"
                  />
                </LazyLoad>
              </div>
              <div
                className='w-11/12 md:w-3/4 z-20 max-h-350'>
                <LazyLoad once className='w-full h-full object-cover'>
                  <UIImageChooseLink
                    data={details?.artikel?.data?.attributes?.images[1]?.url}
                    alt={details?.artikel?.data?.attributes?.images[1]?.alt}
                    isLarge={true}
                    style="w-full h-full object-cover"
                  />
                </LazyLoad>
              </div>
            </>
          )
        )}
      </div>
      <FeatureButtonWrapper
        buttons={details.buttons}
        objekt={details.objekt}
        nyproduktion={details.nyproduktion}
        artikel={details.artikel}
        style='w-1/2 md:w-1/2 flex flex-col justify-center pr-5'>
        {details && (
          <motion.div
            initial={{opacity: 0, x: 20}}
            whileInView={{opacity: [0, 0.3, 0.8, 1], x: 0}}
            viewport={{once: true}}
            transition={{
              delay: 0.4,
              x: {type: 'easeOut', stiffness: 100},
              default: {duration: 1},
            }} className='font-bold text-9 md:text-15'>
            {mapSubheader(details)}
          </motion.div>)}
        {details && (
          <motion.div
            initial={{opacity: 0, x: 20}}
            whileInView={{opacity: [0, 0.3, 0.8, 1], x: 0}}
            viewport={{once: true}}
            transition={{
              delay: 0.6,
              x: {type: 'easeOut', stiffness: 100},
              default: {duration: 1},
            }} className='font-bold text-xl md:text-40 lg:text-50 leading-6 md:leading-13 py-2 md:py-5'>
            {mapTitle(details)}
          </motion.div>
        )}
        {details && (
          <motion.div
            initial={{opacity: 0, x: 20}}
            whileInView={{opacity: [0, 0.3, 0.8, 1], x: 0}}
            viewport={{once: true}}
            transition={{
              delay: 0.8,
              x: {type: 'easeOut', stiffness: 100},
              default: {duration: 1},
            }} className='text-9 md:text-15 py-2 lg:w-440'>
            {mapDescription(details)}
          </motion.div>)}
        <motion.div
          initial={{opacity: 0, x: 20}}
          whileInView={{opacity: [0, 0.3, 0.8, 1], x: 0}}
          viewport={{once: true}}
          transition={{
            delay: 0.9,
            x: {type: 'easeOut', stiffness: 100},
            default: {duration: 1},
          }} className='pt-4 md:pt-11'>
          <FeatureButton
            buttons={details.buttons}
            objekt={details.objekt}
            nyproduktion={details.nyproduktion}
            artikel={details.artikel}/>
        </motion.div>
      </FeatureButtonWrapper>
    </div>
  );
};

export default FeatureCollage;
