import React, {FC} from 'react';
import {motion} from 'framer-motion';
import icon1 from '../../assets/Ikon1.svg';
import icon2 from '../../assets/Ikon2.svg';
import icon3 from '../../assets/Ikon3.svg';
import icon4 from '../../assets/Ikon4.svg';
import icon5 from '../../assets/Ikon5.svg';
import addDefaultSrc from '../../utils/addDefultImageSrc';

type NewProductionPageProgressBarProps = {
  status: string;
}

const NewProductionPageProgressBar: FC<NewProductionPageProgressBarProps> = ({status}) => {
  return (
    <div className={
      'mx-4 pb-8 md:mx-0 md:pb-12 lg:mx-12 lg:pb-12 flex justify-between relative'}>
      <motion.div
        initial={{width: 0}}
        whileInView={{opacity: [0, 0.3, 0.8, 1], width: '88%'}}
        viewport={{once: true}}
        transition={{
          delay: 0.6,
          x: {type: 'easeOut', stiffness: 100},
          default: {duration: 2},
        }} className={
          'h-1px lg:h-0.5 bottom-26 absolute bg-black -z-30 left-8 md:bottom-36 lg:bottom-40 md:hidden'}>
      </motion.div>
      <motion.div
        initial={{width: 0}}
        whileInView={{opacity: [0, 0.3, 0.8, 1], width: '88%'}}
        viewport={{once: true}}
        transition={{
          delay: 0.6,
          x: {type: 'easeOut', stiffness: 100},
          default: {duration: 2},
        }} className={
          'h-1px lg:h-0.5 bottom-26 absolute bg-black -z-30 left-8 md:bottom-36 lg:bottom-40 hidden ' +
          'md:block lg:hidden'}>
      </motion.div>
      <motion.div
        initial={{width: 0}}
        whileInView={{opacity: [0, 0.3, 0.8, 1], width: '89%'}}
        viewport={{once: true}}
        transition={{
          delay: 0.6,
          x: {type: 'easeOut', stiffness: 100},
          default: {duration: 2},
        }} className={
          'h-1px lg:h-0.5 hidden bottom-26 absolute bg-black -z-30 left-8 ' +
        'md:bottom-36 lg:bottom-40 lg:block xl:hidden'}>
      </motion.div>
      <motion.div
        initial={{width: 0}}
        whileInView={{opacity: [0, 0.3, 0.8, 1], width: '92%'}}
        viewport={{once: true}}
        transition={{
          delay: 0.6,
          x: {type: 'easeOut', stiffness: 100},
          default: {duration: 2},
        }} className={
          'h-1px lg:h-0.5 hidden bottom-26 absolute bg-black -z-30 left-8 ' +
        'md:bottom-36 lg:bottom-40 xl:block 2xl:hidden'}>
      </motion.div>
      <motion.div
        initial={{width: 0}}
        whileInView={{opacity: [0, 0.3, 0.8, 1], width: '95%'}}
        viewport={{once: true}}
        transition={{
          delay: 0.6,
          x: {type: 'easeOut', stiffness: 100},
          default: {duration: 2},
        }} className={
          'h-1px lg:h-0.5 hidden bottom-26 absolute bg-black -z-30 left-8 ' +
        'md:bottom-36 lg:bottom-40 2xl:block'}>
      </motion.div>
      <motion.div
        initial={{opacity: 0, y: 20}}
        whileInView={{opacity: [0, 0.3, 0.8, 1], y: 0}}
        viewport={{once: true}}
        transition={{
          delay: 0.2,
          x: {type: 'easeOut', stiffness: 100},
          default: {duration: 1},
        }} className="flex items-center flex-col w-14 md:w-auto">
        <div className={
          `${status === 'Kommande' ? 'border-2 border-black' : ''}` +
          ' bg-gray-100 rounded-full flex justify-center items-center text-gray-600' +
          ' w-10 h-10 md:w-14 md:h-14 lg:w-20 lg:h-20'}>
          <img src={icon1} className='w-10 h-10 md:w-14 md:h-14 lg:w-20 lg:h-20' onError={addDefaultSrc}/>
        </div>
        <div className={
          'text-9 md:text-sm lg:text-base font-light text-center leading-3 md:leading-5 py-4 break-words' +
          ' md:w-28 lg:w-32'}>
          Kommande / projektering</div>
      </motion.div>
      <motion.div
        initial={{opacity: 0, y: 20}}
        whileInView={{opacity: [0, 0.3, 0.8, 1], y: 0}}
        viewport={{once: true}}
        transition={{
          delay: 0.4,
          x: {type: 'easeOut', stiffness: 100},
          default: {duration: 1},
        }} className="flex items-center flex-col w-14 md:w-auto">
        <div className={
          `${status === 'Projektstart' ? 'border-2 border-black' : ''}` +
          ' bg-gray-100 rounded-full flex justify-center items-center text-gray-600' +
          ' w-10 h-10 md:w-14 md:h-14 lg:w-20 lg:h-20'}>
          <img src={icon2} className='w-10 h-10 md:w-14 md:h-14 lg:w-20 lg:h-20' onError={addDefaultSrc}/>
        </div>
        <div className={
          'text-9 md:text-sm lg:text-base font-light md:w-28 lg:w-32 text-center leading-3 md:leading-5 py-4'}>
          Projektstart / Intresseanmälan</div>
      </motion.div>
      <motion.div
        initial={{opacity: 0, y: 20}}
        whileInView={{opacity: [0, 0.3, 0.8, 1], y: 0}}
        viewport={{once: true}}
        transition={{
          delay: 0.6,
          x: {type: 'easeOut', stiffness: 100},
          default: {duration: 1},
        }} className="flex items-center flex-col w-14 md:w-auto">
        <div className={
          `${status === 'Säljstart' ? 'border-2 border-black' : ''}` +
          ' bg-gray-100 rounded-full flex justify-center items-center text-gray-600' +
          ' w-10 h-10 md:w-14 md:h-14 lg:w-20 lg:h-20'}>
          <img src={icon3} className='w-10 h-10 md:w-14 md:h-14 lg:w-20 lg:h-20' onError={addDefaultSrc}/>
        </div>
        <div className={
          'text-9 md:text-sm lg:text-base font-light md:w-28 lg:w-32 text-center leading-3 md:leading-5 py-4'}>
          Säljstart / Avtal</div>
      </motion.div>
      <motion.div
        initial={{opacity: 0, y: 20}}
        whileInView={{opacity: [0, 0.3, 0.8, 1], y: 0}}
        viewport={{once: true}}
        transition={{
          delay: 0.8,
          x: {type: 'easeOut', stiffness: 100},
          default: {duration: 1},
        }} className="flex items-center flex-col w-14 md:w-auto">
        <div className={
          `${status === 'Platsbesök' ? 'border-2 border-black' : ''}` +
          ' bg-gray-100 rounded-full flex justify-center items-center text-gray-600' +
          ' w-10 h-10 md:w-14 md:h-14 lg:w-20 lg:h-20'}>
          <img src={icon4} className='w-10 h-10 md:w-14 md:h-14 lg:w-20 lg:h-20' onError={addDefaultSrc}/>
        </div>
        <div className={
          'text-9 md:text-sm lg:text-base font-light md:w-28 lg:w-32 text-center leading-3 md:leading-5 py-4'}>
          Platsbesök / Avtal</div>
      </motion.div>
      <motion.div
        initial={{opacity: 0, y: 20}}
        whileInView={{opacity: [0, 0.3, 0.8, 1], y: 0}}
        viewport={{once: true}}
        transition={{
          delay: 1,
          x: {type: 'easeOut', stiffness: 100},
          default: {duration: 1},
        }} className="flex items-center flex-col w-14 md:w-auto">
        <div className={
          `${status === 'Slutbeatlning' ? 'border-2 border-black' : ''}` +
          ' bg-gray-100 rounded-full flex justify-center items-center text-gray-600' +
          ' w-10 h-10 md:w-14 md:h-14 lg:w-20 lg:h-20'}>
          <img src={icon5} className='w-10 h-10 md:w-14 md:h-14 lg:w-20 lg:h-20' onError={addDefaultSrc}/>
        </div>
        <div className={
          'text-9 md:text-sm lg:text-base font-light md:w-28 lg:w-32 text-center leading-3 md:leading-5 py-4'}>
          Slutbetalning / Inflytt</div>
      </motion.div>
    </div>
  );
};

export default NewProductionPageProgressBar;
