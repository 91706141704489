import React, {FC, useState} from 'react';
import LazyLoad from 'react-lazyload';
import {MdArrowForwardIos, MdArrowBackIos} from 'react-icons/md';
import {Media} from '../../types';
import UINoImage from '../UICommon/UINoImage';
import UIImageChooseLink from '../UICommon/UIImageChooseLink';

type FeatureCarouselProps = {
  media: Media;
}

const FeatureCarousel: FC<FeatureCarouselProps> = ({media}) => {
  const [current, setCurrent] = useState(0);
  const sliderLength = media.data.length;

  const nextSlide = () => {
    setCurrent(current === sliderLength - 1 ? 0 : current + 1);
  };

  const prevSlide = () => {
    setCurrent(current === 0 ? sliderLength - 1 : current - 1);
  };

  return (
    <>
      {(Object.keys(media.data).length === 0 || sliderLength < 0 || media?.data === null) ? (
        <UINoImage/>
      ) : (
        <div className='relative flex justify-center items-center h-full'>
          <MdArrowBackIos
            className='text-white absolute left-4 md:left-10 text-3xl cursor-pointer z-10'
            onClick={prevSlide}
          />
          <MdArrowForwardIos
            className='text-white absolute right-4 md:right-10 text-3xl cursor-pointer z-10'
            onClick={nextSlide}
          />
          {media?.data && media?.data.map((el, idx) => (
            <div
              key={idx}
              className={idx === current ? 'transition-all opacity-100 duration-300 h-full w-full object-cover' :
              'transition-all opacity-70 duration-300 h-full object-cover'}
            >
              {idx === current && (
                <>
                  {el.attributes?.ext === '.mp4' ? (
                    <LazyLoad once className='w-full h-full'>
                      <video
                        role="presentation"
                        crossOrigin="anonymous"
                        playsInline={true}
                        preload="auto"
                        muted={true}
                        loop={true}
                        width="100%"
                        height="100%"
                        autoPlay={true}
                        className={`w-screen h-full max-h-820 object-cover brightness-50`}
                        src={`${el?.attributes?.url}`}
                      >
                      </video>
                    </LazyLoad>
                ) : (
                  <LazyLoad once className='w-full h-full'>
                    <UIImageChooseLink
                      data={el?.attributes?.url}
                      alt={el?.attributes?.alternativeText}
                      isLarge={true}
                      style="w-full h-full object-cover"
                    />
                  </LazyLoad>
                )}
                </>
              )}
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default FeatureCarousel;
