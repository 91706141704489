import React from 'react';
import {motion} from 'framer-motion';
import InnerHTML from 'dangerously-set-html-content';
import {ourPhilosophyPageApi} from '../api';
import UIBackToTop from '../components/UICommon/UIBackToTopButton';
import OurPhilosophyFeature from '../components/OurPhilosophyPage/OurPhilosophyPageFeature';
import OurPhilosophyFeatureType from '../types/our-philosophy-feature';
import OurPhilosophySquare from '../components/OurPhilosophyPage/OurPhilosophyPageSquare';

const ourPhilosophyPageResource = ourPhilosophyPageApi.fetchData();

const OurPhilosophyPage = () => {
  const ourPhilosophyPageData = ourPhilosophyPageResource.content.read();
  const features = ourPhilosophyPageData?.attributes?.ourPhilosophyFeature;

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const heroItem = {
    description: ourPhilosophyPageData.attributes.heroText.description,
    header: ourPhilosophyPageData.attributes.heroText.header,
    media: ourPhilosophyPageData.attributes.heroMedia,
    vimeo: ourPhilosophyPageData.attributes.vimeo,
  };

  return (
    <div>
      <div className='h-auto min-h-screenWithoutNav pb-10 md:pb-0'>
        <div className='w-full h-full flex flex-col justify-center items-center min-h-screenWithoutNav'>
          <div className='h-auto md:h-1/2 flex items-end px-8 md:px-5 lg:px-10 max-w-screen-2xl'>
            <motion.div
              initial={{opacity: 0, y: 20}}
              whileInView={{opacity: [0, 0.3, 0.8, 1], y: 0}}
              viewport={{once: true}}
              transition={{
                delay: 0.2,
                x: {type: 'easeOut', stiffness: 100},
                default: {duration: 1},
              }} className={
                'text-xl md:text-35 md:leading-10 w-full lg:text-[38px] pt-3 md:pt-6 ' +
                'pb-0 md:pb-0 lg:w-1/2 lg:leading-12'
              }>
              {ourPhilosophyPageData.attributes.header}
            </motion.div>
          </div>
          <div
            className={
              'px-0 md:px-5 lg:px-10 max-w-screen-2xl mx-auto xl:min-h-490 h-600 md:h-450 lg:h-530 mb-10 md:mb-0'}>
            <OurPhilosophyFeature
              item={heroItem}
              variant='right'
            />
          </div>
        </div>
      </div>
      {features && features.map((el: OurPhilosophyFeatureType, idx: number) => (
        <div key={idx}
          className="md:px-5 lg:px-10 max-w-screen-2xl mx-auto">
          {idx % 2 == 0 ? (
            <div>
              <div className='hidden md:block'>
                <OurPhilosophySquare
                  key={idx}
                  item={el}
                  variant='left'
                />
              </div>
              <div className='md:hidden'>
                <OurPhilosophyFeature
                  key={idx}
                  item={el}
                  variant='left'
                />
              </div>
            </div>
          ) : (
            <div>
              <div className='hidden md:block'>
                <OurPhilosophySquare
                  key={idx}
                  item={el}
                  variant='right'
                />
              </div>
              <div className='md:hidden'>
                <OurPhilosophyFeature
                  key={idx}
                  item={el}
                  variant='right'
                />
              </div>
            </div>
          )}
        </div>
      ))}
      {ourPhilosophyPageData.attributes.disclaimer && (
        <motion.div
          initial={{opacity: 0, y: 20}}
          whileInView={{opacity: [0, 0.3, 0.8, 1], y: 0}}
          viewport={{once: true}}
          transition={{
            delay: 0.2,
            x: {type: 'easeOut', stiffness: 100},
            default: {duration: 1},
          }} className='font-bold mx-auto text-15 max-w-260 py-10'>
          <InnerHTML html={ourPhilosophyPageData.attributes.disclaimer}/>
        </motion.div>
      )}
      <motion.div
        onClick={scrollToTop}
        initial={{opacity: 0, y: 20}}
        whileInView={{opacity: [0, 0.3, 0.8, 1], y: 0}}
        viewport={{once: true}}
        transition={{
          delay: 0.4,
          x: {type: 'easeOut', stiffness: 100},
          default: {duration: 1},
        }}>
        <UIBackToTop />
      </motion.div>
    </div>
  );
};

export default OurPhilosophyPage;
