import React, {FC, useState} from 'react';
import {GrAdd} from 'react-icons/gr';
import {motion} from 'framer-motion';
import {PersonDetails} from '../../types';
import UISingleMedia from '../UICommon/UISingleMedia';

type ContactPagePeopleDetailsProps = {
  header: string;
  peoples: PersonDetails[];
}

const ContactPagePeopleDetails: FC<ContactPagePeopleDetailsProps> = ({peoples, header}) => {
  const [active, setActive] = useState(false);
  const [isShown, setIsShown] = useState(false);
  const [currentIdx, setCurrentIdx] = useState<number>();

  const toggleActive = () => {
    setActive(!active);
  };

  const handleHover = (e: any, idx: any) => {
    if (!active) {
      setCurrentIdx(idx);
      setIsShown(true);
    }
  };
  const handleLeave = (e: any, idx: any) => {
    if (!active) {
      setCurrentIdx(idx);
      setIsShown(false);
    }
  };

  return (
    <div
      className={
        'flex flex-col items-center w-full text-white bg-gray-footer md:bg-transparent ' +
      'px-5 py-10 md:px-0 md:mb-20'}>
      <div className='md:border-b border-white w-full pb-4 md:w-4/5 lg:w-2/3 2xl:w-1/2 mx-auto md:mx-20'>
        <motion.div
          initial={{opacity: 0, y: 20}}
          whileInView={{opacity: [0, 0.3, 0.8, 1], y: 0}}
          viewport={{once: true}}
          transition={{
            delay: 0.4,
            x: {type: 'easeOut', stiffness: 100},
            default: {duration: 1},
          }} className={
            'text-xl md:text-35 font-bold max-w-xs md:leading-9 text-center md:text-left px-16 md:px-0 leading-6 ' +
          'mx-auto md:ml-0'}>
          {header}</motion.div>
      </div>
      {peoples && peoples.map((el, idx) => (
        <div
          onMouseOver={(e) => handleHover(e, idx)}
          onMouseOut={(e) => handleLeave(e, idx)}
          key={idx}
          className={
            `${isShown && idx === currentIdx ? 'md:bg-gray-contact transition-all duration-500' :
            'transition-all duration-500' } h-72 md:h-32`+
            '  w-full mx-4 flex md:flex-row justify-between'}
        >
          <motion.div
            initial={{opacity: 0, y: 20}}
            whileInView={{opacity: [0, 0.3, 0.8, 1], y: 0}}
            viewport={{once: true}}
            transition={{
              delay: 0.8,
              x: {type: 'easeOut', stiffness: 100},
              default: {duration: 1},
            }} className={
              'w-full flex justify-between items-center md:w-4/5 lg:w-2/3 2xl:w-1/2 mx-auto border-b border-white' +
              ' py-5 flex-col-reverse md:flex-row'}>
            <div
              className='relative cursor-pointer md:hidden mt-6'
              onClick={toggleActive}
            >
              <div className='h-5 w-0.5 bg-white'></div>
              <div className='h-0.5 w-5 bg-white absolute top-2 -right-2'></div>
            </div>
            <div className='flex flex-col justify-center md:max-w-lg xl:max-w-2xl'>
              <div className={
                `${isShown && idx === currentIdx ? 'md:text-black transition-all duration-500' :
                'transition-all duration-500 text-white' }` +
                ' text-lg md:text-35 font-bold pb-4 text-center md:text-left'}>
                {el.fullname}
              </div>
              <div className={
                `${isShown && idx === currentIdx ? 'md:text-black transition-all duration-500' :
                'transition-all duration-500 text-white' }` +
                ' text-xs md:text-15 text-center md:text-left px-16 md:px-0 md:pr-8'}>
                {el.proffesion && el.proffesion}
                {el.phoneNumber && (`, Mobil: ${el.phoneNumber}`)}
                {el.email && (` Email: ${el.email}`)}
              </div>
            </div>
            <div className='flex items-center justify-center md:pr-10 relative'>
              <div className={
                `${isShown && idx === currentIdx ? 'transition-all duration-500 right-2' :
                'transition-all duration-500 right-10' }` +
                ' w-32 h-32 md:w-24 md:h-24 md:mr-14 md:absolute'
              }>
                <UISingleMedia media={el.media} style="rounded-full"/>
              </div>
              <div
                className={
                  `${isShown && idx === currentIdx ? 'plus-animation transition-all duration-500' :
                  'transition-all duration-500 plus-animation-reverse' }` +
                  ' relative md:flex justify-center items-center cursor-pointer hidden'}
                onClick={toggleActive}
              >
                <div className='h-7 w-3px bg-white'></div>
                <div className='h-3px w-7 bg-white absolute -left-3'></div>
              </div>
              <div
                className={
                  `${isShown && idx === currentIdx ? 'transition-all duration-500 bg-black text-white ' +
                  ' rounded-full z-50 text-xs cursor-pointer right-0' :
                  'transition-all duration-500 opacity-0 right-0 flex ' } w-32 h-32 md:w-24 md:h-24 absolute ` +
                  ' md:flex justify-center items-center hidden'}
                onClick={toggleActive}>
                  LÄS MER
              </div>
            </div>
            <div className={
              `${active === true && idx === currentIdx ? 'w-full md:w-3/4 xl:w-2/5 opacity-100 ' +
              'overflow-scroll lg:w-2/3' :
              'w-0  overflow-hidden opacity-0 p-0 '} ` +
              'top-0 h-full right-0 flex flex-col items-center z-50 fixed bg-white ' +
              'p-6 text-black transition-all duration-300'}>
              <div className='flex justify-end w-full'>
                <GrAdd className="h-8 w-8 rotate-45 cursor-pointer" onClick={toggleActive} />
              </div>
              <div className='w-5/6 p-7'>
                <UISingleMedia media={el.media}/>
              </div>
              <motion.div
                initial={{opacity: 0, y: 20}}
                whileInView={{opacity: [0, 0.3, 0.8, 1], y: 0}}
                viewport={{once: true}}
                transition={{
                  delay: 0.2,
                  x: {type: 'easeOut', stiffness: 100},
                  default: {duration: 1},
                }} className='text-15 flex flex-col justify-center items-center'>
                {el.fullname && (<div>{el.fullname}</div>)}
                {el.proffesion && (<div>{el.proffesion}</div>)}
                {el.phoneNumber && (<div>Mobil: {el.phoneNumber}</div>)}
                {el.email && (
                  <div>Email: <a className='underline' href={`mailto:${el.email}`}>{el.email}</a></div>
                )}
              </motion.div>
              <motion.div
                initial={{opacity: 0, y: 20}}
                whileInView={{opacity: [0, 0.3, 0.8, 1], y: 0}}
                viewport={{once: true}}
                transition={{
                  delay: 0.6,
                  x: {type: 'easeOut', stiffness: 100},
                  default: {duration: 1},
                }} className="my-8">
                <a className='bg-black py-1 px-12 text-white text-15 text-center my-8'>{el.buttonText}</a>
              </motion.div>
              <motion.div
                initial={{opacity: 0, y: 20}}
                whileInView={{opacity: [0, 0.3, 0.8, 1], y: 0}}
                viewport={{once: true}}
                transition={{
                  delay: 0.8,
                  x: {type: 'easeOut', stiffness: 100},
                  default: {duration: 1},
                }} className='text-center text-2xl max-w-lg mx-auto'
                dangerouslySetInnerHTML={{__html: el.detailsHeader}}></motion.div>
              <motion.div
                initial={{opacity: 0, y: 20}}
                whileInView={{opacity: [0, 0.3, 0.8, 1], y: 0}}
                viewport={{once: true}}
                transition={{
                  delay: 1,
                  x: {type: 'easeOut', stiffness: 100},
                  default: {duration: 1},
                }} className='text-center text-15 max-w-lg mx-auto py-10'
                dangerouslySetInnerHTML={{__html: el.detailsDescription}}></motion.div>
            </div>
          </motion.div>
        </div>
      ))}
    </div>
  );
};

export default ContactPagePeopleDetails;
