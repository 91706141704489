import React, {FC} from 'react';
import {motion} from 'framer-motion';
import removeInlineStyles from '../../utils/removeInlineStyles';

type AccordionReusablePartProps = {
  title: string;
  isElementActive: any;
  setIsElementActive: any;
  element: any;
}

const AccordionReusablePart: FC<AccordionReusablePartProps> = ({
  title,
  isElementActive,
  setIsElementActive,
  element,
}) => {
  return (
    <div className="accordion-item">
      <div
        className="flex items-center cursor-pointer my-1 md:my-2"
        onClick={() => setIsElementActive(!isElementActive)}
      >
        <div className='text-xl md:text-3xl mr-4'>{isElementActive ? '-' : '+'}</div>
        <div className='text-xl md:text-3xl'>{title}</div>
      </div>
      <div onClick={() => title !== 'Dokument' ? setIsElementActive(!isElementActive) : null}
        className={`${isElementActive ? 'h-auto mb-4 md:mb-10 transition-all duration-300 cursor-pointer' :
    'h-0 transition-all overflow-hidden opacity-0 duration-300 '}` +
    'md:flex justify-between w-full'
        }>
        <div className='text-sm font-normal pl-7 w-full'>
          {element.map((el: any, idx: any) => (
            <motion.div
              initial={{opacity: 0, y: 20}}
              whileInView={{opacity: [0, 0.3, 0.8, 1], y: 0}}
              viewport={{once: true}}
              transition={{
                delay: 0.1 * idx,
                x: {type: 'easeOut', stiffness: 100},
                default: {duration: 1},
              }} key={idx} className="py-4">
              <div className='font-bold'>{el.title}</div>
              <div className="accordion-item" dangerouslySetInnerHTML={{__html: removeInlineStyles(el.content)}}></div>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AccordionReusablePart;
