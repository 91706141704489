/* eslint-disable no-unused-vars */
import React, {useEffect, useState} from 'react';
import {motion} from 'framer-motion';
import {useCookies} from 'react-cookie';
import {Link} from 'react-router-dom';

const UICookieBanner = ({
  cookieDescription,
  cookieText,
  cookieReadMore,
}: {
  cookieDescription: string,
  cookieText: string;
  cookieReadMore: string;
}) => {
  const [cookies, setCookie] = useCookies(['cookie-info']);
  const [isAccepted, setIsAccepted] = useState(false);

  useEffect(() => {
    if (cookies['cookie-info'] === 'accepted-cookie') {
      setIsAccepted(true);
    }
  }, [cookies]);

  function closeCookie() {
    setCookie('cookie-info', 'accepted-cookie', {path: '/'});
    setIsAccepted(true);
  }

  return (
    <>
      {(cookieDescription && !isAccepted) && (
        <motion.div
          initial={{opacity: 0, x: -100}}
          whileInView={{opacity: 1, x: 0}}
          viewport={{once: true}}
          transition={{
            delay: 0.1,
            x: {type: 'easeOut', stiffness: 100},
            default: {duration: 0.3},
          }}
          className={
            'border-2 border-gray-footer bg-white w-5/6 relative max-w-lg ' +
            'px-5 py-7 text-sm font-semibold mx-auto shadow-accent md:flex md:flex-col md:items-start ' +
            'md:w-2/3 md:mx-10 md:px-6 md:py-10 md:justify-between lg:w-2/5 xl:w-1/3'
          }>
          <div className='pb-5 pr-12 z-40 md:max-w-md md:pb-0 md:pr-6 mb-6'>
            {cookieDescription}
          </div>
          <div className="flex">
            <button
              onClick={() =>closeCookie()}
              className={
                'border-2 border-black font-semibold tracking-tighter ' +
                'px-3 py-0.5 max-h-7 mr-6'}>
              {cookieText}
            </button>
            <Link
              to='/kakor'
              className={
                'border-2 border-gray-footer text-white bg-gray-footer font-semibold tracking-tighter ' +
                'px-3 py-0.5 max-h-7'}
            >{cookieReadMore}</Link>
          </div>
        </motion.div>
      )}
    </>
  );
};
export default UICookieBanner;
