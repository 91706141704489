import React, {FC, useState} from 'react';
import LazyLoad from 'react-lazyload';
import {MdAdd} from 'react-icons/md';
import UIImageChooseLink from './UIImageChooseLink';

type UIGalleryImageProps = {
  data: any;
}
const UIGalleryImage: FC<UIGalleryImageProps> = ({data}) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <>
      <div className="py-1 lg:py-5 flex items-end relative">
        <LazyLoad once className='w-full'>
          <UIImageChooseLink
            data={data.url}
            style={
              `${isActive ? 'transition-all brightness-50 duration-300' :
              'transition-all brightness-100 duration-300'} w-full`}
          />
        </LazyLoad>
        {data.title !== null && data.title.length > 0 && (
          <div
            className={`right-0 bottom-0 pb-5 pr-3 md:pb-10 md:pr-5 flex-row-reverse absolute flex items-end ` +
            ` w-full h-full`}>
            <div onClick={() => setIsActive(!isActive)}>
              <MdAdd className="h-9 w-9 cursor-pointer text-white pt-2" />
            </div>
            <div
              className={`${isActive ?
              'transition-all text-white duration-300 block opacity-100 pl-5' :
              'transition-all text-white duration-300 hidden opacity-0'}`}>
              {data.title}</div>
          </div>
        )}
      </div>
    </>
  );
};
export default UIGalleryImage;
