import React, {FC} from 'react';
import {Buttons} from '../../types';
import {
  Link,
} from 'react-router-dom';

type FeatureButtonWrapperProps = {
  buttons: Buttons;
  objekt?: any;
  nyproduktion?: any;
  artikel?: any;
  children: any;
  style?: any;
}

const FeatureButtonWrapper: FC<FeatureButtonWrapperProps> = ({
  buttons, objekt, nyproduktion, artikel, children, style}) => {
  if (objekt !== undefined && objekt.data !== null && objekt) {
    return (
      <Link
        to={`/objekt/${objekt?.data?.attributes?.fasadID}`}
        className={`${style}`}
      >
        {children}
      </Link>
    );
  } else if (nyproduktion !== undefined && nyproduktion.data !== null && nyproduktion) {
    return (
      <Link
        to={`/nyproduktion/${nyproduktion?.data?.attributes?.fasadID}`}
        className={`${style}`}
      >
        {children}
      </Link>
    );
  } else if (artikel !== undefined && artikel.data !== null && artikel) {
    return (
      <Link
        to={`/artikel/${artikel?.data?.attributes?.fasadID}`}
        className={`${style}`}
      >
        {children}
      </Link>
    );
  } else if (buttons.length > 0) {
    return (
      <Link
        to={buttons[0]?.buttonLink}
        className={`${style}`}
      >
        {children}
      </Link>
    );
  } else {
    return (
      <div
        className={`${style}`}
      >
        {children}
      </div>
    );
  }
};

export default FeatureButtonWrapper;
