import React, {FC, useState} from 'react';
import {FiSearch} from 'react-icons/fi';
import {motion} from 'framer-motion';

type UIContactUsProps = {
  fasadId: any;
  color: string;
  header: string;
}

const UIContactUs: FC<UIContactUsProps> = ({fasadId, color, header}) => {
  type Values = {
    fName : string,
    lName : string,
    email : string,
    phone : string,
    message : string,
  }

  const [values, setValues] = useState<Values>({
    fName: '',
    lName: '',
    email: '',
    phone: '',
    message: '',
  });

  const [isSending, setIsSending] = useState(false);
  const [isSent, setIsSent] = useState(false);

  const handleChange = (event : React.ChangeEvent<HTMLInputElement>) => {
    setValues({...values, [event.target.name]: event.target.value});
  };

  const sendRequest = (event : React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const firstName = values.fName;
    const lastName = values.lName;
    const mail = values.email;
    const phone = values.phone;
    const msg = values.message;
    const object = fasadId;
    const type = 4;

    if (isSending) return;
    setIsSending(true);
    const requestOptions = {
      method: 'GET',
      mode: 'no-cors' as RequestMode,
      headers: {'Content-Type': 'application/jsonp'},
    };
    fetch(
        // eslint-disable-next-line max-len
        `https://crm.fasad.eu/api/customerqueue/addinterestcustomertoqueue?fkobject=${object}&firstname=${firstName}&lastname=${lastName}&mail=${mail}&cellphone=${phone}&message=${msg}&policytypeid=${type}`,
        requestOptions,
    ).then((response) => response.json());
    setIsSending(false);
    setIsSent(true);
  };

  return (
    <motion.div
      initial={{opacity: 0, y: 20}}
      whileInView={{opacity: [0, 0.3, 0.8, 1], y: 0}}
      viewport={{once: true}}
      transition={{
        delay: 0.3,
        x: {type: 'easeOut', stiffness: 100},
        default: {duration: 1},
      }} className={
        ` flex flex-col justify-center w-full md:w-4/5 lg:w-4/6 xl:w-7/12 mx-auto mt-6 lg:mt-24 ${color}`}>
      <div className='w-full px-8 md:w-2/3 mx-auto pt-10 md:pt-20 lg:pt-28 pb-4 md:pb-10 lg:pb-14 max-w-xl'>
        {!isSent ? (
          <form onSubmit={(e) => sendRequest(e)}>
            <div className='flex items-end mb-2 py-4'>
              <FiSearch className='w-6 h-6 lg:w-11 lg:h-11 mr-4'/>
              <div className=' text-15 font-bold mt-3'>
            Bevakning
              </div>
            </div>
            <div className={
              `${color === 'bg-beige-contact' ? 'font-bold' : ''}` +
          ' text-35 leading-10 pb-16'}>
              {header}
            </div>
            <div className='text-15'>
              <div className='pb-1'>
                <div className='pb-2'>Förnamn<sup>*</sup></div>
                <input
                  className='bg-transparent w-full border-b border-black'
                  onChange={handleChange}
                  name={'fName'}
                  required
                />
              </div>
              <div className='pb-1'>
                <div className='pb-2'>Efternamn<sup>*</sup></div>
                <input
                  className='bg-transparent w-full border-b border-black'
                  onChange={handleChange}
                  name={'lName'}
                  required
                />
              </div>
              <div className='pb-1'>
                <div className='pb-2'>E-postadress<sup>*</sup></div>
                <input
                  className='bg-transparent w-full border-b border-black'
                  onChange={handleChange}
                  name={'email'}
                  required
                />
              </div>
              <div className='pb-1'>
                <div className='pb-2'>Mobil</div>
                <input
                  className='bg-transparent w-full border-b border-black'
                  onChange={handleChange}
                  name={'phone'}
                  required
                />
              </div>
              <div className='pb-1'>
                <div className='pb-2'>Meddelande</div>
                <input
                  className='bg-transparent w-full border-b border-black'
                  onChange={handleChange}
                  name={'message'}
                  required
                />
              </div>
            </div>
            <button type={'submit'} className='text-15 font-bold text-center py-10 w-full'>Skicka</button>
            <div className='text-11 text-center'>Tack för dina uppgifter!</div>
          </form>
        ) : (
          <div className={
            `${color === 'bg-beige-contact' ? 'font-bold' : ''}` +
        ' text-35 leading-10 pb-16'}>
            Tack för ditt intresse!<br/>
            Vi hör av oss till dig.
          </div>
        )}
      </div>
    </motion.div>
  );
};

export default UIContactUs;
