import React, {FC} from 'react';
import {motion} from 'framer-motion';
import FeatureMedia from '../Feature/FeatureMedia';
import UINoImage from '../UICommon/UINoImage';

type OurPhilosophyFeatureProps = {
  item: any;
  variant: string;
}

const OurPhilosophyFeature: FC<OurPhilosophyFeatureProps> = ({item, variant}) => {
  const showMedia = (item: any) => {
    if (item.vimeo !== null && item.vimeo.length > 0) {
      let segments = [];
      let videoId = '';
      segments = item.vimeo.split('/');
      videoId = segments[segments.length - 1];
      return (
        <div className="w-full h-full overflow-x-hidden vimeo-video-wrapper-feature relative">
          <iframe
            title={'title'}
            // eslint-disable-next-line max-len
            src={`https://player.vimeo.com/video/${videoId}?speed=0&autoplay=1&controls=0&background=1&quality=720p&loop=1&muted=1}`}
            height="100%"
            frameBorder="0"
            className={`w-full h-full object-cover`}
            allowFullScreen></iframe>
        </div>
      );
    } else if (item.media !== null) {
      return (
        <FeatureMedia media={item.media} hideBlend={true}/>
      );
    } else {
      return (
        <UINoImage />
      );
    }
  };

  return (
    <>
      {variant === 'left' ? (
        <div className='flex flex-col md:flex-row h-full md:min-h-470'>
          {(item.header && item.description) && (
            <div className='w-full lg:w-1/2 flex flex-col justify-center p-8 lg:p-16 xl:p-24 h-3/5 md:h-full'>
              {item.header && (
                <motion.div
                  initial={{opacity: 0, y: 20}}
                  whileInView={{opacity: [0, 0.3, 0.8, 1], y: 0}}
                  viewport={{once: true}}
                  transition={{
                    delay: 0.2,
                    x: {type: 'easeOut', stiffness: 100},
                    default: {duration: 1},
                  }} className='font-Notepaper text-50'>
                  {item.header}
                </motion.div>
              )}
              {item.description && (
                <motion.div
                  initial={{opacity: 0, y: 20}}
                  whileInView={{opacity: [0, 0.3, 0.8, 1], y: 0}}
                  viewport={{once: true}}
                  transition={{
                    delay: 0.6,
                    x: {type: 'easeOut', stiffness: 100},
                    default: {duration: 1},
                  }} className='text-sm md:text-base lg:text-xl'>
                  {item.description}
                </motion.div>
              )}
            </div>
          )}
          <div className='w-full lg:w-1/2 h-full relative min-h-370 md:min-h-470'>
            {showMedia(item)}
          </div>
        </div>
      ) : (
        <div className='flex flex-col md:flex-row-reverse h-full md:min-h-470'>
          {(item.header && item.description) && (
            <div className='w-full lg:w-1/2 flex flex-col justify-center p-8 lg:p-16 xl:p-24 h-3/5 md:h-full'>
              {item.header && (
                <motion.div
                  initial={{opacity: 0, y: 20}}
                  whileInView={{opacity: [0, 0.3, 0.8, 1], y: 0}}
                  viewport={{once: true}}
                  transition={{
                    delay: 0.2,
                    x: {type: 'easeOut', stiffness: 100},
                    default: {duration: 1},
                  }} className='font-Notepaper text-50'>
                  {item.header}
                </motion.div>
              )}
              {item.description && (
                <motion.div
                  initial={{opacity: 0, y: 20}}
                  whileInView={{opacity: [0, 0.3, 0.8, 1], y: 0}}
                  viewport={{once: true}}
                  transition={{
                    delay: 0.6,
                    x: {type: 'easeOut', stiffness: 100},
                    default: {duration: 1},
                  }} className='text-sm md:text-base lg:text-xl'>
                  {item.description}
                </motion.div>
              )}
            </div>
          )}
          <div className='w-full lg:w-1/2 h-full min-h-370 relative md:min-h-470'>
            {showMedia(item)}
          </div>
        </div>
      )}
    </>
  );
};

export default OurPhilosophyFeature;
