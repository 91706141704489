import React, {FC, useEffect, useState} from 'react';
import {Feature} from '../../types';
import FeatureCollage from './FeatureCollage';
import FeatureFull from './FeatureFull';
import FeatureSplitLeft from './FeatureSplitLeft';
import FeatureSplitRight from './FeatureSplitRight';

type FeatureSlicesProps = {
  item: Feature;
}

const FeatureSlices: FC<FeatureSlicesProps> = ({item}) => {
  const [color, setColor] = useState('bg-white');
  const elementColor = item?.color;

  const onChangeColor = () => {
    setColor('bg-white');
    if (elementColor === 'beige') {
      setColor('bg-beige-feature');
    }
    if (elementColor === 'gray') {
      setColor('bg-gray-feature');
    }
    if (elementColor === 'transparent') {
      setColor('bg-white');
    }
  };

  useEffect(() => {
    onChangeColor();
  }, [elementColor]);

  if (!item) {
    return null;
  }

  return (
    <div className='md:px-5 lg:px-10 max-w-screen-2xl mx-auto'>
      {item?.variant === 'split-left' && <FeatureSplitLeft details={item} color={color}/>}
      {item?.variant === 'split-right' && <FeatureSplitRight details={item} color={color}/>}
      {item?.variant === 'full' && <FeatureFull details={item}/>}
      {item?.variant === 'collage' && <FeatureCollage details={item}/>}
    </div>
  );
};

export default FeatureSlices;
