/* eslint-disable */
import client from './client';
import {ArticlePage} from '../types';

const qs = require('qs');

async function fetchArticlePage(id: any) : Promise<ArticlePage> {
  const query = qs.stringify({
    filters: {
      fasadID: {
        $eq: id,
      },
    },
    populate: {
      populate: ['Title', 'shortDescription', 'firstDescription', 'secondDescription', 'startFilm'],
      images: {
        populate: ['*'],
      },
      blueprint: {
        populate: ['*'],
      },
      video: {
          populate: ['*'],
      },
    } 
  }, {
    encodeValuesOnly: true,
  });
  const res = await client.get(`artikels?${query}`);
  return res.data.data;
}

function fetchData(id: any) {
  return fetchArticlePage(id);
}

export default {
  fetchData,
};
