import React, {useEffect, useState} from 'react';
import {motion} from 'framer-motion';
import LazyLoad from 'react-lazyload';
import {articlePageApi} from '../api';
import {GoPlus} from 'react-icons/go';
import {Link, useParams} from 'react-router-dom';
import HeroSlices from '../components/Hero/HeroSlices';
import ObjectPageMainText from '../components/ObjectPage/ObjectPageMainText';
import UILoader from '../components/UICommon/UILoader';
import FeatureMediaURL from '../components/Feature/FeatureMediaURL';
import UIBackToTop from '../components/UICommon/UIBackToTopButton';
import UIImageChooseLink from '../components/UICommon/UIImageChooseLink';


const ArticlePage = () => {
  const {id} = useParams();
  const [data, setData] = useState<any>();

  useEffect(() => {
    articlePageApi.fetchData(id).then((res) => {
      setData(res);
    });
  }, []);

  const hero = {
    id: '1',
    variant: 'split-right',
    media: {
      data: {
        attributes: {
          ext: 'string',
          url: data && data[0]?.attributes?.images[0]?.url,
          alternativeText: 'string',
        },
      },
    },
    description: 'Vill du veta mer om reportaget kontakta ' +
    'fastighetsmäklare Eric Eiderbrant '+
    '<a href="mailto:eric@newnest.se">eric@newnest.se</a>',
    header: data && data[0]?.attributes?.Title,
    subheader: data && data[0]?.attributes?.extraTitle,
    startFilm: data && data[0]?.attributes?.startFilm,
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  let segments2 = [];
  let videoId2 = '';
  if (data && data[0]?.attributes?.vimeoHero != null && data[0]?.attributes?.vimeoHero?.length > 0) {
    segments2 = data[0]?.attributes?.vimeoHero.split('/');
    videoId2 = segments2[segments2.length - 1];
  }

  return (
    <div className='mb-5 md:px-10'>
      {data ? (
        <>
          <HeroSlices item={hero} isBold={true} page='artikel'/>
          {data[0]?.attributes?.images[1]?.url && (
            <LazyLoad once className='w-full max-h-840 overflow-hidden flex justify-center items-center my-5'>
              {videoId2 !== '' ? (
                <iframe
                  title={'title'}
                  // eslint-disable-next-line max-len
                  src={`https://player.vimeo.com/video/${videoId2}?h=b45fcf7929&amp;badge=0&loop=1&autoplay=1&muted=1&amp;autopause=0&amp;player_id=0&amp;app_id=58479`}
                  height="100%"
                  width="100%"
                  frameBorder="0"
                  // eslint-disable-next-line max-len
                  className={`w-full h-screen object-cover xl:mb-[10vw] xl:mt-[0px] lg:my-[-10vh] md:my-[-15vh] sm:my-[-25vh]  my-[-35vh] overflow-x-hidden`}
                  allowFullScreen></iframe>
              ) : (
                <Link
                  to={`/artikel/${id}/gallery`}
                  className="w-full h-full">
                  <UIImageChooseLink
                    data={data[0]?.attributes?.images[1]?.url}
                    style="w-full h-full object-cover"/>
                </Link>
              )}
            </LazyLoad>
          )}
          {(data[0]?.attributes?.firstHeading || data[0]?.attributes?.firstDescription) && (
            <ObjectPageMainText
              title={data[0]?.attributes?.firstHeading}
              description={data[0]?.attributes?.firstDescription}/>
          )}
          {(data[0]?.attributes?.images[2]?.url && data[0]?.attributes?.images[3]?.url) && (
            <div
              className={`flex flex-col md:flex-row space-y-3 md:space-y-0 md:space-x-3 ` +
              `overflow-hidden mb-0 md:mb-6`}>
              <LazyLoad once className='md:w-1/2 h-0 pb-[50%] pt-[50%] md:pb-[25%] md:pt-[25%] relative'>
                <Link
                  to={`/artikel/${id}/gallery`}
                  className="w-full h-full">
                  <UIImageChooseLink
                    data={data[0]?.attributes?.images[2]?.url}
                    style="w-full h-full object-cover absolute left-0 top-0"/>
                </Link>
              </LazyLoad>
              <LazyLoad once className='md:w-1/2 h-0 pb-[50%] pt-[50%] md:pb-[25%] md:pt-[25%] relative'>
                <Link
                  to={`/artikel/${id}/gallery`}
                  className="w-full h-full">
                  <UIImageChooseLink
                    data={data[0]?.attributes?.images[3]?.url}
                    style="w-full h-full object-cover absolute left-0 top-0"/>
                </Link>
              </LazyLoad>
            </div>
          )}
          {data[0]?.attributes?.images.length > 2 && (
            <Link
              to={`/artikel/${id}/gallery`}
              className='flex flex-col justify-center items-center font-bold py-5 cursor-pointer'>
              <GoPlus className="h-4 w-4 mb-0.5 font-bold text-7xl"/>
              <div>alla bilder</div>
            </Link>
          )}
          {(data[0]?.attributes?.blueprint !== null && data[0]?.attributes?.blueprint.length > 0) && (
            <div className={
              'bg-beige-contact relative py-10 w-full lg:max-h-840 overflow-hidden flex justify-center ' +
              'items-center my-5 lg:min-h-840 px-3 max-h-490 min-h-490'}>
              <FeatureMediaURL images={data[0]?.attributes?.blueprint} isBlueprint={true}/>
            </div>
          )}
          {(data[0]?.attributes?.firstQuote && (
            <motion.div
              initial={{opacity: 0, y: 20}}
              whileInView={{opacity: [0, 0.3, 0.8, 1], y: 0}}
              viewport={{once: true}}
              transition={{
                delay: 0.4,
                x: {type: 'easeOut', stiffness: 100},
                default: {duration: 1},
              }} className={
                'max-w-sm px-20 md:px-10 lg:px-10 py-8 md:py-12 lg:py-16 flex justify-center items-center mx-auto'}>
              <div className='font-Notepaper text-35 md:text-40 lg:text-50 text-center leading-7 lg:leading-10'
                dangerouslySetInnerHTML={{__html: data[0]?.attributes?.firstQuote}}></div>
            </motion.div>
          ))}
          {(data[0]?.attributes?.images[4]?.url && data[0]?.attributes?.images[5]?.url) && (
            // eslint-disable-next-line max-len
            <div className='flex flex-col md:flex-row space-y-3 md:space-y-0 md:space-x-3 my-0 md:my-6 overflow-hidden'>
              <LazyLoad once className='md:w-1/2 h-0 pb-[50%] pt-[50%] md:pb-[25%] md:pt-[25%] relative'>
                <Link
                  to={`/artikel/${id}/gallery`}
                  className="w-full h-full">
                  <UIImageChooseLink
                    data={data[0]?.attributes?.images[4]?.url}
                    style="w-full h-full object-cover absolute left-0 top-0"/>
                </Link>
              </LazyLoad>
              <LazyLoad once className='md:w-1/2 h-0 pb-[50%] pt-[50%] md:pb-[25%] md:pt-[25%] relative'>
                <Link
                  to={`/artikel/${id}/gallery`}
                  className="w-full h-full">
                  <UIImageChooseLink
                    data={data[0]?.attributes?.images[5]?.url}
                    style="w-full h-full object-cover absolute left-0 top-0"/>
                </Link>
              </LazyLoad>
            </div>
          )}
          {(data[0]?.attributes?.secondHeading || data[0]?.attributes?.secondDescription) && (
            <ObjectPageMainText
              title={data[0]?.attributes?.secondHeading}
              description={data[0]?.attributes?.secondDescription}/>
          )}
          {data[0]?.attributes?.images[6]?.url && (
            <LazyLoad once className={
              'w-full max-h-840 overflow-hidden flex justify-center items-center my-5'}>
              <Link
                to={`/artikel/${id}/gallery`}
                className="w-full h-full">
                <UIImageChooseLink
                  data={data[0]?.attributes?.images[6]?.url}
                  style="w-full h-full object-cover"/>
              </Link>
            </LazyLoad>
          )}
          <div className='max-w-xs py-8 md:py-8 lg:py-8 flex justify-center items-center mx-auto'>
            <motion.div
              initial={{opacity: 0, y: 20}}
              whileInView={{opacity: [0, 0.3, 0.8, 1], y: 0}}
              viewport={{once: true}}
              transition={{
                delay: 0.4,
                x: {type: 'easeOut', stiffness: 100},
                default: {duration: 1},
              }} className='text-15 font-bold text-center'>
              <div className='font-NotepaperLight text-6xl pb-4'
                dangerouslySetInnerHTML={{__html: data[0]?.attributes?.secondQuote}}></div>
              Vill du veta mer om reportaget kontakta fastighetsmäklare Eric Eiderbrant
              <a href='mailto:eric@newnest.se' className='underline'> eric@newnest.se</a>
            </motion.div>
          </div>
          <motion.div
            onClick={scrollToTop}
            initial={{opacity: 0, y: 20}}
            whileInView={{opacity: [0, 0.3, 0.8, 1], y: 0}}
            viewport={{once: true}}
            transition={{
              delay: 0.4,
              x: {type: 'easeOut', stiffness: 100},
              default: {duration: 1},
            }}>
            <UIBackToTop />
          </motion.div>
        </>
      ) : (
        <UILoader />
      )}
    </div>
  );
};

export default ArticlePage;
