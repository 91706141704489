import React, {FC, useState} from 'react';
import LazyLoad from 'react-lazyload';
import {BsZoomIn} from 'react-icons/bs';
import UINoImage from '../UICommon/UINoImage';
import UIZoomModal from '../UICommon/UIZoomModal';
import FeatureCarouselURL from './FeatureCarouselURL';
import UIImageChooseLink from '../UICommon/UIImageChooseLink';

type FeatureMediaURLProps = {
  images: any;
  isBlueprint?: boolean;
}

const FeatureMediaURL: FC<FeatureMediaURLProps> = ({images, isBlueprint}) => {
  const [active, setActive] = useState(false);
  const changeActive = () => {
    if (active === false) {
      setActive(!active);
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
      setActive(!active);
    }
  };

  if (Object.keys(images).length === 0 || images.length < 0 || images === null || images === undefined) {
    return (
      <UINoImage />
    );
  }
  return (
    <>
      {images && images.length === 1 ? (
        <LazyLoad once className='h-full'>
          {isBlueprint ? (
            <UIImageChooseLink
              data={images[0]?.url}
              alt={images[0]?.alt}
              style={`h-full object-cover`}
            />
          ) : (
            <UIImageChooseLink
              data={images[0]?.url}
              alt={images[0]?.alt}
              isLarge={true}
              style={`h-full object-cover w-full`}
            />
          )}
          {active && (
            <UIZoomModal element={{url: images[0]?.url, alt: images[0]?.alt}} changeActive={changeActive}/>
          )}
          {isBlueprint && (
            <BsZoomIn
              onClick={changeActive}
              className='w-6 h-6 lg:w-8 lg:h-8 absolute right-5 bottom-4 lg:right-20 lg:bottom-14 cursor-pointer'
            />
          )}
        </LazyLoad>
      ) : (
        <FeatureCarouselURL images={images} isBlueprint={isBlueprint}/>
      )}
    </>
  );
};

export default FeatureMediaURL;
