/* eslint-disable */
import client from './client';
import {ObjectPage} from '../types';

const qs = require('qs');

async function fetchNewProductionPage(id: any) : Promise<ObjectPage> {
  const query = qs.stringify({
    filters: {
      fasadID: {
        $eq: id,
      },
    },
    populate: {
      populate: ['salesTitle', 'salesTextShort', 'salesText', 'size', 'fee', 'numberOfRooms', 'address', 'price', 'municipality', 'startFilm', 'beskrivningFilm'],
      images: {
        populate: ['*'],
      },
      blueprint: {
        populate: ['*'],
      },
      objekts: {
        populate: ['*', 'facts'],
      },
      tableButton: {
        populate: ['*'],
      }
    } 
  }, {
    encodeValuesOnly: true,
  });

  const res = await client.get(`nyproduktions?${query}`);
  return res.data.data;
}

function fetchData(id: any) {
  return fetchNewProductionPage(id);
}

export default {
  fetchData,
};
