import React, {FC} from 'react';
import {Link, useParams} from 'react-router-dom';
import {motion} from 'framer-motion';
import InnerHTML from 'dangerously-set-html-content';
import {GoPlus} from 'react-icons/go';
import {Hero} from '../../types';
import UISingleMedia from '../UICommon/UISingleMedia';
import UINoImage from '../UICommon/UINoImage';

type HeroSplitRightProps = {
  details: Hero;
  isBold?: boolean;
  page?: string;
  isShowings?: any;
  isBiddings?: any;
  scrollToShowings?: any;
}

const HeroSplitRight: FC<HeroSplitRightProps> = ({details, isBold, page, isShowings, isBiddings, scrollToShowings}) => {
  const description = details?.description;
  const cleanDescription =
    description?.replaceAll(' / ', ' <span style="font-weight:300; font-size: 12px; margin-bottom: 1px">+</span> ');
  const {id} = useParams();
  let showingsDescription = '';
  if (isShowings && isBiddings) {
    showingsDescription = 'Budgivning & Visning - Läs mer ';
  } else if (isShowings) {
    showingsDescription = 'Visning - Läs mer ';
  } else if (isBiddings) {
    showingsDescription = 'Budgivning - Läs mer ';
  }
  let segments = [];
  let videoId = '';
  if (details.startFilm != null && details.startFilm?.length > 0 ) {
    segments = details.startFilm.split('/');
    videoId = segments[segments.length - 1];
  }

  return (
    <div className={
      'flex flex-col md:flex-row md:space-x-5 mb-5 h-screenWithoutNav min-h-screenWithoutNav md:min-h-fit pb-0 md:pb-4'
    }>
      <div className='w-full h-3/5 md:w-1/2 md:h-full relative'>
        {videoId !== '' ? (
          <div className="w-full h-full overflow-x-hidden vimeo-video-wrapper-feature relative">
            <iframe
              title={'title'}
              // eslint-disable-next-line max-len
              src={`https://player.vimeo.com/video/${videoId}?speed=0&autoplay=1&controls=0&background=1&quality=720p&loop=1&muted=1}`}
              height="100%"
              frameBorder="0"
              className={`w-full h-full object-cover`}
              allowFullScreen></iframe>
          </div>
        ) : (
          <Link
            to={`/${page}/${id}/gallery`}
            className="w-full h-full">
            {details.media?.data?.attributes.url !== undefined && <UISingleMedia media={details.media}/>}
            {details?.media?.data === null && <UINoImage />}
            {details?.media?.data?.attributes.url === undefined && <UINoImage />}
          </Link>
        )}
      </div>
      <div className='text-center w-full md:w-1/2 h-2/5 flex justify-center items-center md:h-full'>
        <div className='max-w-[32rem] px-5 md:px-0'>
          {details.subheader && (
            <motion.div
              initial={{opacity: 0, y: 20}}
              whileInView={{opacity: [0, 0.3, 0.8, 1], y: 0}}
              viewport={{once: true}}
              transition={{
                delay: 0.2,
                x: {type: 'easeOut', stiffness: 100},
                default: {duration: 1},
              }}>
              <InnerHTML
                html={details.subheader}
                className={
                  `${isBold ? 'font-bold' : 'font-medium'}` +
                ' text-11 md:text-15 py-3 md:py-6'}/>
            </motion.div>
          )}
          {details.header && (
            <motion.div
              initial={{opacity: 0, y: 20}}
              whileInView={{opacity: [0, 0.3, 0.8, 1], y: 0}}
              viewport={{once: true}}
              transition={{
                delay: 0.4,
                x: {type: 'easeOut', stiffness: 100},
                default: {duration: 1},
              }}>
              <InnerHTML
                html={details.header}
                className={
                  `${isBold ? 'font-bold' : 'font-light'}` +
                ' text-lg md:text-35 md:leading-10'}/>
            </motion.div>
          )}
          {details.description && (
            <motion.div
              initial={{opacity: 0, y: 20}}
              whileInView={{opacity: [0, 0.3, 0.8, 1], y: 0}}
              viewport={{once: true}}
              transition={{
                delay: 0.6,
                x: {type: 'easeOut', stiffness: 100},
                default: {duration: 1},
              }}>
              <InnerHTML
                html={cleanDescription}
                className={
                  `${isBold ? 'font-medium' : 'font-light'}` +
                ' text-11 md:text-15 px-6 py-4 md:py-8'}/>
            </motion.div>
          )}
          {details?.realtor && (
            <motion.div
              initial={{opacity: 0, y: 20}}
              whileInView={{opacity: [0, 0.3, 0.8, 1], y: 0}}
              viewport={{once: true}}
              transition={{
                delay: 0.6,
                x: {type: 'easeOut', stiffness: 100},
                default: {duration: 1},
              }}>
              <InnerHTML
                html={details?.realtor}
                className={
                  `${isBold ? 'font-medium ' : 'font-light '}` +
                'text-11 md:text-15 px-6 pb-4 md:pb-8 hidden md:block'}/>
            </motion.div>
          )}
          {isShowings || isBiddings ?(
            <motion.div
              initial={{opacity: 0, y: 20}}
              whileInView={{opacity: [0, 0.3, 0.8, 1], y: 0}}
              viewport={{once: true}}
              transition={{
                delay: 0.8,
                x: {type: 'easeOut', stiffness: 100},
                default: {duration: 1},
              }} className="md:pt-6">
              <div onClick={scrollToShowings}
                className='cursor-pointer flex flex-row justify-center items-center font-bold text-11 '>
                <p className='pr-[5px]'>{showingsDescription}</p>
                <GoPlus />
              </div>
            </motion.div>
          ):
          <>
          </>
          }
        </div>
      </div>
    </div>
  );
};

export default HeroSplitRight;
