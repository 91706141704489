import React, {FC} from 'react';
import UIImageChooseLink from '../UICommon/UIImageChooseLink';
import UINoImage from '../UICommon/UINoImage';

type FeatureImageURLProps = {
  images: any;
  isFasadImage?: boolean;
  hasMaxHeight?: boolean;
}

const FeatureImageURL: FC<FeatureImageURLProps> = ({images, isFasadImage, hasMaxHeight}) => {
  if (Object.keys(images).length === 0 || images.length === 0 || images === null) {
    return (
      <UINoImage />
    );
  }
  if (isFasadImage) {
    return (
      <UIImageChooseLink
        data={images[0]?.url}
        alt={images[0]?.alt}
        isLarge={true}
        style={`w-full h-full object-cover ${hasMaxHeight && 'max-h-[90vh]'}`}
      />
    );
  } else {
    return (
      <UIImageChooseLink
        data={images[0]?.url}
        alt={images[0]?.alt}
        isLarge={true}
        style={`w-full h-full object-cover ${hasMaxHeight && 'max-h-[90vh]'}`}
      />
    );
  }
};

export default FeatureImageURL;
