import React, {FC, useState} from 'react';
import {Link} from 'react-router-dom';
import {motion} from 'framer-motion';
import InnerHTML from 'dangerously-set-html-content';
import {TbMail} from 'react-icons/tb';
import {Form} from '../../types';
import UICheckbox from '../UICommon/UICheckbox';

type ContactPageFormProps = {
  contactForm: Form;
  email: string;
}

const ContactPageForm: FC<ContactPageFormProps> = ({contactForm, email}) => {
  const [isSending, setIsSending] = useState(false);
  const [isSent, setIsSent] = useState(false);

  type Values = {
    fName : string,
    lName : string,
    email : string,
    phone : string,
    message : string,
  }

  const [values, setValues] = useState<Values>({
    fName: '',
    lName: '',
    email: '',
    phone: '',
    message: '',
  });

  const handleChange = (event : React.ChangeEvent<HTMLInputElement>) => {
    setValues({...values, [event.target.name]: event.target.value});
  };

  const sendRequest = (event : React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const firstName = values.fName;
    const lastName = values.fName;
    const mail = values.email;
    const phone = values.phone;
    const msg = values.message;
    const object = 753;
    const type = 3;

    if (isSending) return;
    setIsSending(true);
    const requestOptions = {
      method: 'GET',
      mode: 'no-cors' as RequestMode,
      headers: {'Content-Type': 'application/jsonp'},
    };
    fetch(
        // eslint-disable-next-line max-len
        `https://crm.fasad.eu/api/customerqueue/addinterestcustomertoqueue?fkcorporation=${object}&firstname=${firstName}&lastname=${lastName}&mail=${mail}&cellphone=${phone}&message=${msg}&policytypeid=${type}`,
        requestOptions,
    ).then((response) => response.json());
    setIsSending(false);
    setIsSent(true);
  };

  return (
    <div className='bg-white lg:p-28 md:p-16 pb-14 p-5'>
      <div className='flex flex-col justify-center items-center pb-2'>
        <motion.div
          initial={{opacity: 0, y: 20}}
          whileInView={{opacity: [0, 0.3, 0.8, 1], y: 0}}
          viewport={{once: true}}
          transition={{
            delay: 0.2,
            x: {type: 'easeOut', stiffness: 100},
            default: {duration: 1},
          }} className='flex flex-col md:flex-row justify-center items-center mb-4'>
          <div className='bg-black rounded-full p-3 md:p-2 md:mr-2'>
            <TbMail className='w-8 h-8 text-white md:h-7 md:w-7 lg:w-6 lg:h-6' />
          </div>
          <a
            href={`mailto:${email}`}
            className='text-15 md:text-xl mt-2'
          >{email}</a>
        </motion.div>
        {contactForm.header && (
          <motion.div
            initial={{opacity: 0, y: 20}}
            whileInView={{opacity: [0, 0.3, 0.8, 1], y: 0}}
            viewport={{once: true}}
            transition={{
              delay: 0.6,
              x: {type: 'easeOut', stiffness: 100},
              default: {duration: 1},
            }} className="text-xl font-bold leading-6 text-center py-4 md:leading-8 lg:leading-10 md:text-35"
            dangerouslySetInnerHTML={{__html: contactForm.header}}>
          </motion.div>
        )}
        {contactForm.subheader && (
          <motion.div
            initial={{opacity: 0, y: 20}}
            whileInView={{opacity: [0, 0.3, 0.8, 1], y: 0}}
            viewport={{once: true}}
            transition={{
              delay: 1,
              x: {type: 'easeOut', stiffness: 100},
              default: {duration: 1},
            }} className="text-11 text-center md:text-15 mb-6 mt-2">
            {contactForm.subheader}
          </motion.div>
        )}
        {!isSent ? (
          <form onSubmit={(e) => sendRequest(e)}>
            <div className="text-15">
              <div className='pb-1'>
                <div className='pb-2'>Namn<sup>*</sup>
                </div>
                <input
                  className='bg-transparent w-full border-b border-black'
                  onChange={handleChange}
                  name={'fName'}
                  required
                />
              </div>
              <div className='pb-1'>
                <div className='pb-2'>Efternamn<sup>*</sup></div>
                <input
                  className='bg-transparent w-full border-b border-black'
                  onChange={handleChange}
                  name={'lName'}
                  required
                />
              </div>
              <div className='pb-1'>
                <div className='pb-2'>E-postadress<sup>*</sup>
                </div>
                <input
                  className='bg-transparent w-full border-b border-black'
                  onChange={handleChange}
                  name={'email'}
                  required
                />
              </div>
              <div className='pb-1'>
                <div className='pb-2'>Mobil</div>
                <input
                  className='bg-transparent w-full border-b border-black'
                  onChange={handleChange}
                  name={'phone'}
                  required
                />
              </div>
              <div className='pb-1'>
                <div className='pb-2'>Meddelande</div>
                <input
                  className='bg-transparent w-full border-b border-black'
                  onChange={handleChange}
                  name={'message'}
                  required
                />
              </div>
            </div>
            <div className="text-15 py-4">
              {contactForm.checkboxText &&
              contactForm.checkboxText.map((el, idx) => (
                <UICheckbox key={idx} text={el.checkboxText} />
              ))}
            </div>
            {contactForm.submitButton && (
              <button
                className="text-15 font-bold text-center w-full hover:bg-orange-100 md:py-2"
                type={'submit'}
              >
                {contactForm.submitButton}
              </button>
            )}
          </form>
        ) : (
          <div className={'text-xl font-bold leading-6 text-center py-4 md:leading-8 lg:leading-10 md:text-35'}>
            Tack för ditt intresse!<br/>
            Vi hör av oss till dig.
          </div>
        )}
        <div className="text-11 pt-12 text-center">
          {contactForm.disclaimer && (
            <>
              <InnerHTML html={contactForm.disclaimer}/>
              <Link to={contactForm.disclaimerButton.buttonLink}>
                {contactForm.disclaimerButton.buttonText}
              </Link>
            </>
          )}
        </div>
        <div className="text-11 pt-12 text-center font-bold">
          Besöksadresser: Biblioteksgatan 29, 114 35 Stockholm & Katarinavägen 15, 116 45 Stockholm.
        </div>
      </div>
    </div>
  );
};

export default ContactPageForm;
