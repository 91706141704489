/* eslint-disable */
import client from './client';
import {HomePage} from '../types';

const qs = require('qs');
const query = qs.stringify({
  populate: {
    hero: {
      populate: ['media', 'button'],
    },
    featureSingle: {
      populate: ['media', 'buttons', 'title', '*', 'artikel', 'nyproduktion', 'objekt', 'objekt.facts', 'objekt.operatingCosts', 'objekt.building', 'objekt.images', 'objekt.blueprint', 'objekt.documents', 'artikel.images', 'nyproduktion.images'],
    },
    featureRepeatable: {
      populate: ['media', 'buttons', 'title', '*', 'artikel', 'nyproduktion', 'objekt', 'objekt.facts', 'objekt.operatingCosts', 'objekt.building', 'objekt.images', 'objekt.blueprint', 'objekt.documents', 'artikel.images', 'nyproduktion.images'],
    },
  } 
}, {
  encodeValuesOnly: true,
});

async function fetchHomePage() : Promise<HomePage> {
  const res = await client.get(`startsida?${query}`);
  return res.data.data;
}

function fetchData() {
  const contentPromise = fetchHomePage();
  return {
    content: wrapPromise(contentPromise),
  };
}

function wrapPromise(promise : any) {
  let status = 'pending';
  let result : any;
  let suspender = promise.then(
    (r: any) => {
      status = "success";
      result = r;
    },
    (e: any) => {
      status = "error";
      result = e;
    }
  );
  return {
    read() {
      if (status === "pending") {
        throw suspender;
      } else if (status === "error") {
        throw result;
      } else if (status === "success") {
        return result;
      }
    }
  };
}

export default {
  fetchData,
};
