import React from 'react';
import {contactPageApi} from '../api';
import ContactPageForm from '../components/ContactPage/ContactPageForm';
import ContactPageMedia from '../components/ContactPage/ContactPageMedia';
import ContactPagePeopleDetails from '../components/ContactPage/ContactPagePeopleDetails';

const contactPageResource = contactPageApi.fetchData();

const ContactPage = () => {
  const contactPageData = contactPageResource.content.read();
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <div className='mt-5 flex flex-col justify-center md:items-center mx-auto'>
      <div className='hidden md:block fixed -z-50 w-full h-full top-0'>
        {contactPageData?.attributes?.media && (
          <ContactPageMedia item={contactPageData?.attributes}/>
        )}
      </div>
      <div className='md:w-4/5 lg:w-2/3 2xl:w-1/2 mx-auto md:m-20'>
        <ContactPageForm
          contactForm={contactPageData?.attributes?.contactForm}
          email={contactPageData?.attributes?.formEmail}
        />
      </div>
      <ContactPagePeopleDetails
        header={contactPageData?.attributes?.detailsHeader}
        peoples={contactPageData?.attributes?.persons}
      />
      <div onClick={scrollToTop} className="bg-gray-footer md:bg-transparent">
        <div className='pt-6 flex justify-center items-center'>
          <div className='border-white w-10 h-10 border-t-2 border-l-2 rotate-45 mt-2 cursor-pointer'></div>
        </div>
      </div>
    </div>
  );
};

export default ContactPage;
